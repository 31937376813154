import React from "react";

const NoResults = () => {
  return (
    <div className="mt-4 flex h-[400px] flex-col items-center justify-center">
      <img
        src="/umgc-apps/common/images/monitor-search.png"
        alt="Monitor with many magnifying glasses"
        className="w-[250px] object-contain"
      />
      <p className="text-2xl font-bold">No Results</p>
      <p>No classes match your search criteria.</p>
      <p>Please adjust your search and try again.</p>
    </div>
  );
};

export default NoResults;
