import React from "react";

interface ExclamantionTriangleProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const ExclamantionTriangle: React.FC<ExclamantionTriangleProps> = ({
  fillColor,
  ...props
}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="-11 0 597 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M569.517 440.013c18.458 31.9941 -4.71094 71.9873 -41.5762 71.9873h-479.887c-36.9365 0 -59.999 -40.0537 -41.5771 -71.9873l239.946 -416.027c18.4668 -32.0098 64.7197 -31.9521 83.1543 0zM541.859 456.004l-240 -416
        c-6.16016 -10.6777 -21.583 -10.6338 -27.7188 0l-240 416c-6.15723 10.6738 1.59082 23.9961 13.8594 23.9961h480c12.3232 0 19.9902 -13.3691 13.8594 -23.9961zM288 372c-15.4639 0 -28 12.5361 -28 28s12.5361 28 28 28s28 -12.5361 28 -28s-12.5361 -28 -28 -28z
        M276.51 160h22.9795c6.82227 0 12.2734 5.68164 11.9893 12.5l-7 168c-0.267578 6.42773 -5.55566 11.5 -11.9893 11.5h-8.97949c-6.43262 0 -11.7217 -5.07324 -11.9902 -11.5l-7 -168c-0.282227 -6.81836 5.16699 -12.5 11.9902 -12.5zM288 372
        c-15.4639 0 -28 12.5361 -28 28s12.5361 28 28 28s28 -12.5361 28 -28s-12.5361 -28 -28 -28z"
        fill={fillColor ? fillColor : "#ffffff"}
      />
    </svg>
  );
};

export default ExclamantionTriangle;
