import "./style.css";

import * as Dialog from "@radix-ui/react-dialog";
import Header from "components/Header";
import Link from "components/Link";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import Select from "components/select/Select";
import CaretDown from "icons/CaretDown";
import React from "react";
import { GradingEnum, GradingModalState } from "types/editGradingModal";

const gradingOptions = [
  {
    $id: GradingEnum.Graded,
    $name: "GRADED",
  },
  {
    $id: GradingEnum.Audid,
    $name: "AUDIT",
  },
  {
    $id: GradingEnum.PassFail,
    $name: "PASS/FAIL",
  },
];

interface EditGradingProps {
  career: string;
  gradingModal: GradingModalState;
  setGradingModal: React.Dispatch<React.SetStateAction<GradingModalState>>;
  actionButton: (
    isValidOption: boolean,
    handleOnClick: () => void
  ) => React.ReactNode;
}

const EditGrading: React.FC<EditGradingProps> = ({
  career,
  gradingModal,
  setGradingModal,
  actionButton,
}) => {
  const isValidOption = Boolean(
    gradingOptions.find((g) => g.$id === gradingModal.newGrading)
  );

  const handleOnClick = () => {
    if (!isValidOption) return;

    setGradingModal((prev) => ({
      ...prev,
      isEditModalOpen: false,
    }));

    setTimeout(() => {
      setGradingModal((prev) => ({
        ...prev,
        isConfirmModalOpen: true,
      }));
    }, 100);
  };

  return (
    <Dialog.Root
      open={gradingModal.isEditModalOpen}
      onOpenChange={(isOpen) => {
        setGradingModal((prev) => ({
          ...prev,
          isEditModalOpen: isOpen,
        }));
      }}
    >
      <ModalWrapper
        show={gradingModal.isEditModalOpen}
        className="h-[530px] w-[55vw]"
        ariaLabelledby="edit-grading-label"
      >
        <div className="mb-6 flex justify-between">
          <h2 id="edit-grading-label">
            <Header>Edit Grading</Header>
          </h2>
          <Dialog.Close
            className="border-none text-3xl"
            aria-label="Close"
            autoFocus
          >
            &times;
          </Dialog.Close>
        </div>
        <Paragraph className="mb-4">
          Update your grading preferences. UMGC has four grading methods: graded
          (standard), audit, pass/fail, and satisfactory/D/F. Graded is most
          common and counts towards your degree requirements. In some instances,
          a permission code may be required.{" "}
          <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
            Contact an advisor
          </Link>{" "}
          if you need a permission code.
        </Paragraph>
        <div className="grid grid-cols-3 gap-6">
          <div>
            {career === "UGRD" && (
              <div>
                <Paragraph>
                  Within the first week of class, you may update your grading
                  preferences for some classes. UMGC has four grading methods:
                  graded (standard), audit, pass/fail, and satisfactory/D/F,
                  which vary depending on the class. Graded is most common and
                  counts towards your degree requirements.{" "}
                </Paragraph>
                <Paragraph>
                  <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                    Contact an advisor
                  </Link>{" "}
                  to fully understand the impact before making any grading basis
                  changes. To make a change, select the new grading basis from
                  the drop-down below.
                </Paragraph>
              </div>
            )}
            {career !== "UGRD" && (
              <div>
                <Paragraph>
                  Within the first week of class, you may update your grading
                  preferences for some classes. UMGC has four grading methods:
                  graded (standard), audit, pass/fail, and satisfactory/D/F,
                  which vary depending on the class. Graded is most common and
                  counts towards your degree requirements.{" "}
                </Paragraph>
                <Paragraph>
                  Graduate students must{" "}
                  <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                    contact an advisor
                  </Link>{" "}
                  to change the grading basis.
                </Paragraph>
              </div>
            )}
          </div>
          <div>
            <Paragraph className="font-bold">Grading</Paragraph>
            <Select
              ariaLabel="Grading"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              currItem={gradingOptions.find(
                (g) => g.$id === gradingModal.newGrading
              )}
              items={gradingOptions}
              onChange={(item) => {
                setGradingModal((prev) => ({
                  ...prev,
                  newGrading: item.$id,
                }));
              }}
              containerClassName="min-w-[120px]"
              className="box-border flex h-[40px] w-full min-w-[120px] items-center border-[1px] border-solid border-[#ADADAD] pl-2.5 text-[15px] font-medium"
              icon={
                <div className="flex h-[38px] w-[40px] cursor-pointer items-center justify-center border-none bg-du-skyblue">
                  <CaretDown className="h-4 w-4" fillColor="white" />
                </div>
              }
            />
          </div>
        </div>
        <div className="mt-[30px] flex flex-row justify-end gap-2 border-0 border-t border-solid border-du-gray pt-[30px]">
          <Dialog.Close className="border border-solid border-du-yellow px-4 py-2 font-bold">
            Cancel
          </Dialog.Close>
          {actionButton(isValidOption, handleOnClick)}
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default EditGrading;
