import React from "react";

interface ParagraphProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({ children, className, id }) => {
  return (
    <p id={id} className={`m-0 block text-[15px] leading-6 ${className ?? ""}`}>
      {children}
    </p>
  );
};

export default Paragraph;
