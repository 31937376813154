import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import { CartDetail, ClassCS, CourseSection } from "types/classSearchService";
import { UcMyplanCRSPlnr } from "types/degreePlanningDashboardService";
import { GradingEnum } from "types/editGradingModal";
import { useEventListener } from "usehooks-ts";

import { loadingStateUpdate } from "../utils";
import CourseTable from "./CourseTable";
import {
  getClassSectionResponse,
  getPlannerCoursesResponse,
  getShoppingCartResponse,
  getSubjectListResponse,
  orderOnsiteClassFirst,
} from "./resources";

// custom window event types
declare global {
  interface WindowEventMap {
    classSearchSubmitEvent: CustomEvent<{ arg: any }>;
    termUpdatedEvent: CustomEvent<{ arg: any }>;
  }
}

export interface Params {
  career: string;
  strm: string;
  acadCareer: string;
  termFormat: string;
  emplid: string;
  institution: string;
  impersonate?: "true" | "false";
}

export interface ClassUI extends ClassCS {
  inCart: boolean;
  gradingBasisCart: GradingEnum;
  showClass: boolean;
}

export interface CourseUI extends Omit<CourseSection, "Class"> {
  open: boolean;
  showMenu: boolean;
  plannerStatus: "add" | "remove" | "loading";
  subjectDesc: string;
  Class: ClassUI[];
}

interface CourseProps {
  adelphiStudent?: boolean;
  impersonate?: "true" | "false";
}

const Course: React.FC<CourseProps> = ({ adelphiStudent, impersonate }) => {
  const [courseList, setCourseList] = useState<CourseUI[]>([]);
  const [courseListError, setCourseListError] = useState("");
  const [plannerList, setPlannerList] = useState<UcMyplanCRSPlnr[]>([]);
  const [cartList, setCartList] = useState<CartDetail[]>([]);
  const [params, setParams] = useState<Params>({
    career: "",
    strm: "",
    acadCareer: "",
    termFormat: "",
    emplid: "",
    institution: "",
  });

  const updatePlannerProps = (courseList: CourseUI[]) => {
    for (let i = 0; i < courseList.length; i++) {
      const courseItem = courseList[i];
      courseItem.plannerStatus = "add"; // remove loading if any
      const courseName = `${courseItem.SUBJECT} ${courseItem.CATALOG_NBR}`;
      for (let i = 0; i < plannerList.length; i++) {
        const plannerItem = plannerList[i];
        if (plannerItem.COURSE === courseName) {
          courseItem.plannerStatus = "remove";
        }
      }
    }
    return courseList;
  };

  const updateCartProps = (courseList: CourseUI[]) => {
    for (let i = 0; i < courseList.length; i++) {
      const courseItem = courseList[i];
      for (let j = 0; j < courseItem.Class.length; j++) {
        const classItem = courseItem.Class[j];
        classItem.inCart = false;
        const cartItem = cartList.find((item) => {
          return item.ClassNbr === classItem.CLASS_NBR;
        });
        if (cartItem) {
          classItem.inCart = true;
          switch (cartItem.GradingBasis) {
            case GradingEnum.Graded:
              classItem.gradingBasisCart = GradingEnum.Graded;
              break;
            case GradingEnum.Audid:
              classItem.gradingBasisCart = GradingEnum.Audid;
              break;
            case GradingEnum.PassFail:
              classItem.gradingBasisCart = GradingEnum.PassFail;
              break;
            default:
              classItem.gradingBasisCart = GradingEnum.Other;
              break;
          }
        }
      }
    }
    return courseList;
  };

  const updateShowCourse = (courseList: CourseUI[], finalUrl: string) => {
    const param = new URLSearchParams(finalUrl);
    const openOrNot = param.get("openclass") || "";

    courseList.forEach((course) => {
      course.Class.forEach((classItem) => {
        if (new Date(classItem.START_DT) > new Date()) {
          classItem.showClass = true;
        } else {
          classItem.showClass = false;
        }
      });
    });

    for (let i = 0; i < courseList.length; i++) {
      const courseData = courseList[i];
      const fDataTrue = courseData.Class.slice().filter((x) => x.showClass);
      if (fDataTrue.length == 0) {
        if (openOrNot == "Y") {
          courseData.Class = [];
          const val: number = i;
          courseList.splice(i, val + 1);
          i = i - 1;
        } else {
          courseData.Class.forEach((st) => {
            if (new Date(st.START_DT) < new Date()) {
              st.ENRL_STAT_DESC = "Closed";
            }
          });
        }
      } else {
        courseData.Class = [];
        courseData.Class = fDataTrue;
      }
    }
    if (courseList.length == 0) {
      setCourseListError("No Records");
    }
    return courseList;
  };

  useEffect(() => {
    setCourseList((prevCourseList) => {
      let newCourseList = prevCourseList.slice(0);
      newCourseList = updatePlannerProps(newCourseList);
      return newCourseList;
    });
  }, [plannerList]);

  useEffect(() => {
    setCourseList((prevCourseList) => {
      let newCourseList = prevCourseList.slice(0);
      newCourseList = updateCartProps(newCourseList);
      return newCourseList;
    });
  }, [cartList]);

  useEventListener("classSearchSubmitEvent", async (e: any) => {
    loadingStateUpdate(true);
    const finalUrl = e.detail.finalUrl;
    let courseList: CourseUI[] = [];
    setCourseList([]); // remove previous list
    setCourseListError("");
    if (finalUrl === "") {
      loadingStateUpdate(false);
      return;
    }

    try {
      const classSectionResponse = await getClassSectionResponse(finalUrl);
      setParams((prev) => ({
        ...prev,
        emplid: classSectionResponse?.ClassSectionResponse?.emplid ?? "",
        institution:
          classSectionResponse?.ClassSectionResponse?.institution ?? "",
        impersonate,
      }));

      if ((classSectionResponse as any)?.ClassSearchFault?.ErrMsg) {
        setCourseListError(
          (classSectionResponse as any)?.ClassSearchFault?.ErrMsg
        );
      }
      if (!classSectionResponse?.ClassSectionResponse?.CourseSection) return;
      let courseSection =
        classSectionResponse.ClassSectionResponse.CourseSection;
      courseSection = orderOnsiteClassFirst(courseSection, adelphiStudent);

      // only for class search modal
      const chipSectionElement = document.getElementById("du-chip-section");
      if (chipSectionElement) {
        chipSectionElement.style.display = "block";
      }

      const subjectList = await getSubjectListResponse();
      const subjectItem = subjectList.find(
        (s) => s.Code === classSectionResponse.ClassSectionResponse.Subject
      );
      const subjectDesc = subjectItem?.Description
        ? subjectItem.Description
        : "";

      courseList = courseSection.map((courseItem) => {
        return {
          ...courseItem,
          open: false,
          showMenu: false,
          plannerStatus: "add",
          subjectDesc,
          Class: courseItem.Class.map((classItem) => ({
            ...classItem,
            inCart: false,
            gradingBasisCart: GradingEnum.Other,
            showClass: false,
          })),
        };
      });
      courseList = updateShowCourse(courseList, finalUrl);
      courseList = updatePlannerProps(courseList);
      courseList = updateCartProps(courseList);
      setCourseList(courseList);
    } catch (error) {
      const errorObj = error as any;
      if (errorObj?.status === 504) {
        setCourseListError(
          "Your search retrieved too many results. Please add additional search criteria and try again"
        );
      } else {
        setCourseListError(
          "An error has occurred. Please try your search again."
        );
      }
    } finally {
      loadingStateUpdate(false);
    }
  });

  useEventListener("termUpdatedEvent", async (e: any) => {
    const { career, strm, acadCareer, termFormat } = e.detail;
    const plannerList = await getPlannerCoursesResponse(career);
    const cartList = await getShoppingCartResponse(strm, acadCareer);
    setParams((prev) => ({
      ...prev,
      career,
      strm,
      termFormat,
      acadCareer,
      impersonate,
    }));
    if (plannerList) setPlannerList(plannerList);
    if (cartList) setCartList(cartList);
  });

  return (
    <div>
      {courseList.length > 0 && (
        <>
          <div className="mb-4 flex gap-3 text-[15px]">
            <div className="all-revert flex items-center gap-1">
              <div className="inline-block h-[15px] w-[15px] rounded-full bg-[#108A27]" />
              <span>Seats Available</span>
            </div>
            <div className="all-revert flex items-center gap-1">
              <div className="inline-block h-[15px] w-[15px] rounded-full bg-[#8A1410]" />
              <span>Closed</span>
            </div>
            <div className="all-revert flex items-center gap-1">
              <div className="inline-block h-[15px] w-[15px] rounded-full bg-[#FCB319]" />
              <span>Wait List</span>
            </div>
          </div>
          <CourseTable
            courseList={courseList}
            setCourseList={setCourseList}
            params={params}
            refreshTable={async () => {
              const plannerList = await getPlannerCoursesResponse(
                params.career
              );
              const cartList = await getShoppingCartResponse(
                params.strm,
                params.acadCareer
              );
              if (plannerList) setPlannerList(plannerList);
              if (cartList) setCartList(cartList);
            }}
          />
        </>
      )}
      {courseListError !== "" && <p>{courseListError}</p>}
    </div>
  );
};

angularize(Course, "courseReact", angular.module("duApp"), {
  adelphiStudent: "<",
  impersonate: "<",
});

export default Course;
