import * as Dialog from "@radix-ui/react-dialog";
import Header from "components/Header";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import React, { useState } from "react";

interface ConfirmClassRemoveProps {
  trigger: React.ReactNode;
  action: () => void;
}

const ConfirmClassRemove: React.FC<ConfirmClassRemoveProps> = ({
  trigger,
  action,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <ModalWrapper show={isOpen} className="h-[280px] max-h-[280px] w-[55vw]">
        <div className="mb-6 flex justify-between">
          <Header>Remove Class</Header>
          <Dialog.Close className="border-none text-3xl">&times;</Dialog.Close>
        </div>
        <Paragraph>
          Are you sure you want to remove this class from your shopping cart?
          Doing so means that registering for through the shopping cart will not
          enroll you for this class.
        </Paragraph>
        <div className="mt-[30px] flex flex-row justify-end gap-4 border-0 border-t border-solid border-du-gray pt-[30px]">
          <Dialog.Close className="border-0 px-4 py-2 font-bold text-du-blue">
            No
          </Dialog.Close>
          <button
            onClick={() => {
              setIsOpen(false);
              action();
            }}
            className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
          >
            OK
          </button>
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default ConfirmClassRemove;
