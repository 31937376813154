import clsx from "clsx";
import { useDataList } from "components/autocomplete/useDataList";
import { useOnClickOutsideMany } from "hooks/useOnClickOutsideMany";
import React, { RefObject, useEffect, useId, useRef, useState } from "react";
import { GenericItem } from "types/select";

import DataList from "./DataList";

interface SelectProps<T> {
  items: T[];
  currItem?: T;
  onChange: (newItem: T) => void;
  className?: string;
  containerClassName?: string;
  icon: React.ReactNode;
  ariaLabel?: string;
}

const Select = <T extends GenericItem>({
  items,
  currItem,
  onChange,
  className,
  containerClassName,
  icon,
  ariaLabel,
}: SelectProps<T>) => {
  const listRef = useRef<HTMLUListElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const listboxId = useId();
  const [showValues, setShowValues] = useState(false);
  const {
    suggestions: values,
    currSuggestId,
    handleChange: handleChangeDL,
    handleKeyEnter: handleKeyEnterDL,
    handleKeyArrow: handleKeyArrowDL,
  } = useDataList<T>(listRef, true);

  useEffect(() => {
    setShowValues(false);
    handleChangeDL(items);
  }, [currItem]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === "Enter" || e.key === " ") && !showValues) {
      e.preventDefault();
      setShowValues((prev) => !prev);
    } else if ((e.key === "Enter" || e.key === " ") && showValues) {
      e.preventDefault();
      const currSuggestId = handleKeyEnterDL();
      const newItem = values.find((val) => val.$id === currSuggestId);
      if (newItem) {
        onChange(newItem);
      }
      setShowValues((prev) => !prev);
    } else if (e.key === "Escape" || e.key === "Tab") {
      setShowValues(false);
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      handleKeyArrowDL(e.key);
    }
  };

  const handleOnClick = (item: T) => {
    onChange(item);
  };

  useOnClickOutsideMany([divRef as RefObject<HTMLElement>, listRef], () => {
    setShowValues(false);
  });

  return (
    <div
      className="relative focus:outline focus:outline-2"
      onKeyDown={handleKeyDown}
      onClick={() => {
        setShowValues((prev) => !prev);
      }}
      tabIndex={0}
      aria-label={ariaLabel}
      aria-expanded={showValues}
      role="combobox"
      aria-autocomplete="none"
      aria-haspopup="listbox"
      aria-activedescendant={
        showValues ? values.find((v) => v.$id === currSuggestId)?.$id : ""
      }
      aria-controls={listboxId}
    >
      <div
        className={clsx("relative", containerClassName ?? containerClassName)}
        ref={divRef}
      >
        <div className={className}>{currItem ? currItem.$name : ""}</div>
        <div className="absolute right-[1px] top-[1px]">{icon}</div>
      </div>
      {showValues && (
        <DataList
          id={listboxId}
          options={values}
          currSuggestId={currSuggestId}
          setItem={handleOnClick as (item: GenericItem) => void}
          rect={divRef.current?.getBoundingClientRect()}
          ref={listRef}
        />
      )}
    </div>
  );
};

export default Select;
