import React from "react";

interface UnorderedListProps {
  children?: React.ReactNode;
  className?: string;
}

const UnorderedList: React.FC<UnorderedListProps> = ({
  children,
  className,
}) => {
  return <ul className={`list-none p-0 ${className ?? ""}`}>{children}</ul>;
};

export default UnorderedList;
