/* eslint-disable react/display-name */
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { GenericItem } from "types/select";

interface DataListProps {
  suggestions: GenericItem[];
  currSuggestId: string;
  setItem: (item: GenericItem) => void;
  input: string;
  rect?: DOMRect;
  style?: React.CSSProperties;
}

const DataList = forwardRef<HTMLUListElement, DataListProps>(
  ({ suggestions, currSuggestId, setItem, input, rect, style }, ref) => {
    return (
      <ul
        ref={ref}
        className="absolute left-0 top-[40px] z-10 max-h-[228px] cursor-pointer overflow-y-auto bg-white !py-2.5 text-sm shadow-md"
        style={{
          width: `${rect?.width}px`,
          ...style,
        }}
      >
        {suggestions.map((item) => {
          const name = item.$name;
          const parts = parse(
            name,
            match(name, input, {
              insideWords: true,
              findAllOccurrences: true,
            })
          );
          return (
            <li
              value={item.$id}
              className={clsx(
                "mb-0 whitespace-pre-wrap !py-4 px-4 text-[15px] hover:bg-du-light-gray",
                currSuggestId === item.$id ? "bg-du-light-gray" : ""
              )}
              key={item.$id}
              onClick={() => setItem(item)}
            >
              {parts.map((p, idx) => (
                <span
                  key={idx}
                  className={clsx(p.highlight ? "font-bold" : "font-normal")}
                >
                  {p.text}
                </span>
              ))}
            </li>
          );
        })}
      </ul>
    );
  }
);

export default DataList;
