import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { FetchStatus } from "types/fetch";
import { MilitaryInformationResponse } from "types/profileMilitaryService";
import { sendEvents } from "utils/googleTagManager";

const MilitaryHeader = () => {
  const handleSelect = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    const customEvent = new CustomEvent("handleValueSelectedUpdate", {
      detail: "1",
    });
    window.dispatchEvent(customEvent);
  };

  const [showInformation, setShowInformation] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const [angProps, setAngProps] = useState<{
    CENR: string | undefined;
    ACTIVE_STUDENT: string | undefined;
    urlHostName: string | undefined;
    studentCareer: string | undefined;
    domianCheck: string | undefined;
  }>({
    CENR: undefined,
    ACTIVE_STUDENT: undefined,
    urlHostName: undefined,
    studentCareer: undefined,
    domianCheck: undefined,
  });

  const [pendoProps, setPendoProps] = useState<{
    MilitaryStatus: string | undefined;
    DutyStatus: string | undefined;
    ServiceAffiliation: string | undefined;
    DutyStation: string | undefined;
  }>({
    MilitaryStatus: undefined,
    DutyStatus: undefined,
    ServiceAffiliation: undefined,
    DutyStation: undefined,
  });

  useEffect(() => {
    sendEvents();
  }, []);

  useEffect(() => {
    // get some variables from angular
    // access rootScope values from outside angular
    const ngAppEle = angular.element(document.querySelector("[data-ng-app]"));
    const injector = ngAppEle.injector();
    const $rootScope = injector.get("$rootScope");
    setAngProps({
      CENR: $rootScope?.CENR,
      ACTIVE_STUDENT: $rootScope?.ACTIVE_STUDENT,
      urlHostName: $rootScope?.urlHostName,
      studentCareer: $rootScope?.studentCareer,
      domianCheck: $rootScope?.domianCheck,
    });
  }, []);

  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(
    FetchStatus.LOADING
  );

  const sendPendoData = () => {
    setPendoProps({
      MilitaryStatus: "",
      DutyStatus: "",
      ServiceAffiliation: "",
      DutyStation: "",
    });
  };

  const getMilitaryInformation = async () => {
    console.log("Method Called");
    console.log("militaryPromises");
    const data = (await getDuService({
      service: "profileMilitaryService",
      method: "getProfileMilitary",
    })) as MilitaryInformationResponse;
    console.log("async" + data.Military_Resp.EMPLID);
    const milInfo = data.Military_Resp.UC_DU_MIL_DATA;

    let maxDate = "";
    let minDate = "";
    let dutyStationLocationLatest = "";
    let dutyStationCountryLatest = "";

    milInfo.map((item) => {
      maxDate = item.EFFDT;
      console.log("maxDate:" + maxDate.length);
      if (maxDate.length == 1) {
        setShowInformation(true);
      }
    });
    milInfo.map((item) => {
      minDate = item.EFFDT;
    });

    milInfo.forEach((item) => {
      if (item.EFFDT > maxDate) {
        maxDate = item.EFFDT;
      } else if (item.EFFDT < minDate) {
        minDate = item.EFFDT;
      }
    });
    for (let i = 0; i < milInfo.length; i++) {
      if (maxDate == milInfo[i].EFFDT) {
        dutyStationLocationLatest = milInfo[i].UC_CUR_MIL_DUTY_STA;
        dutyStationCountryLatest = milInfo[i].UC_CURRENT_COUNTRY;
        const dataArray: any[] = [
          {
            MilitaryStatus: milInfo[i].UC_MIL_STATUS_DESCR,
            DutyStatus: milInfo[i].UC_MIL_DUTY_STA_DESCR,
            ServiceAffiliation: milInfo[i].UC_MIL_BRANCH_DESCR,
            DutyStation: milInfo[i].UC_CUR_DESCR100,
          },
        ];
        sendDataToAngular(dataArray);
      }
    }
  };

  const sendDataToAngular = (dataArray: any[]) => {
    const event = new CustomEvent("reactToAngularEvent", {
      detail: dataArray,
    });
    document.dispatchEvent(event);
  };

  useEffect(() => {
    console.log("Div Loaded");
    getMilitaryInformation();
  }, []);

  return (
    <div>
      <div className="flex hidden items-center gap-2 sm:block">
        <div className="flex items-center">
          <div>
            <h3 className="font-secondary text-2xl  font-light md:text-[27px]">
              Military Information
            </h3>
          </div>
          <span
            role="button"
            aria-label="edit military information"
            tabIndex={0}
            className="after:content ml-2 cursor-pointer border border-none after:font-awesome after:text-lg after:font-light after:content-['\f304'] focus:bg-white"
            onClick={handleSelect}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSelect(event);
              }
            }}
          ></span>
        </div>
      </div>
      <br />
      {showInformation ? (
        <p className="m-0 p-0 text-sm font-light leading-loose">
          We currently do not have any military information for you. If this is
          incorrect, please provide your current military information.
        </p>
      ) : (
        <p>{showInformation}</p>
      )}

      <div className="sm:hidden">
        <div className="border-b bg-gray-200">
          <div
            onClick={handleToggle}
            className="flex cursor-pointer items-center justify-between p-4 "
            style={{ fontFamily: "Open Sans,sans-serif", fontSize: "1.125rem" }}
          >
            <div className="flex items-center">
              {expanded ? (
                <span
                  className="ml-2 mr-3 cursor-pointer border  border-none
                after:font-awesome after:text-2xl after:font-bold after:content-['\f0d7']"
                ></span>
              ) : (
                <span
                  className="ml-2 mr-3 cursor-pointer border  border-none
                   after:font-awesome after:text-2xl after:font-bold after:content-['\f0da']"
                ></span>
              )}
              <div className="mr-3"> Military Information</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
angularize(MilitaryHeader, "militaryHeaderReact", angular.module("duApp"), {});
export default MilitaryHeader;
