import "./financialAid.css";

import React from "react";
import { angularize } from "react-in-angularjs";

const FinancialResources = () => {
  return (
    <section
      aria-labelledby="Scholarships"
      id=""
      className="border-box resources-cls -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)]
       moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)] relative  
       mb-[1.25rem] mr-[0.05rem] bg-[#ffffff] p-[1.25rem]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        marginLeft: "1.65rem",
      }}
    >
      <div className="flex-justify-between">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
            }}
          >
            Financial Resources
          </h3>
        </div>
      </div>

      <br />
      <div className="flex-justify-between">
        <div className="text-left">
          <a
            id="dropAndWithdrawalPolicy"
            href="https://www.umgc.edu/current-students/course-registration/registration/drop-and-withdrawal"
            target="blank"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Drop and Withdrawal Policy
          </a>
          <br />
          <a
            id="paymentFAQs"
            href="https://www.umgc.edu/current-students/finances/payments/faqs"
            target="blank"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Payment FAQs
          </a>
          <br />
          <a
            id="refunds"
            href=" https://www.umgc.edu/current-students/finances/payments/refunds"
            target="blank"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Refunds
          </a>
          <br />
          <a
            id="residency"
            href="https://www.umgc.edu/current-students/finances/tuition/residency-for-tuition-purposes"
            target="blank"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Residency
          </a>
          <br />
          <a
            id="tuitionRates"
            href="https://www.umgc.edu/current-students/finances/tuition"
            target="blank"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Tuition Rates
          </a>
        </div>
      </div>
    </section>
  );
};

angularize(
  FinancialResources,
  "financialResourcesReact",
  angular.module("duApp"),
  {}
);
export default FinancialResources;
