import { range } from "./range";

export const paginate = (current: number, max: number) => {
  const neighbors = 1;
  const boundary = neighbors + 2;
  const nl =
    neighbors + (max - current < boundary ? boundary - (max - current) : 0);
  const nr =
    neighbors + (current - 1 < boundary ? boundary - (current - 1) : 0);
  const pages: (string | number)[] = range(1, max + 1).filter((page) => {
    if (page === 1 || page === max) return true;
    if (page >= current - nl && page <= current + nr) return true;
    return false;
  });

  if (pages.length >= neighbors * 2 + 2 + 1) {
    if (Number(pages[1]) - Number(pages[0]) > 1) {
      pages.splice(1, 0, "...");
    }
    if (Number(pages[pages.length - 1]) - Number(pages[pages.length - 2]) > 1) {
      pages.splice(pages.length - 1, 0, "...");
    }
  }

  return pages;
};
