export const canvasStyle = {
  position: "absolute",
  bottom: 0,
  left: 0,
  height: 0,
  overflow: "hidden",
  "padding-top": 0,
  "padding-bottom": 0,
  border: "none",
};

export const mirrorProps = [
  "box-sizing",
  "width",
  "font-size",
  "font-weight",
  "font-family",
  "font-style",
  "letter-spacing",
  "text-indent",
  "white-space",
  "word-break",
  "overflow-wrap",
  "padding-left",
  "padding-right",
];
