import React from "react";

const Alert = () => {
  return (
    <span
      className="before:inline-block before:font-awesome before:text-2xl before:font-light before:text-du-red 
  before:content-['\f071'] hover:cursor-pointer hover:underline"
    />
  );
};

export default Alert;
