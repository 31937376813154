import { getPlannerCoursesResponse } from "pages/classSearch/course/resources";
import React, { useEffect, useReducer, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { StudentTermResponse } from "types/classSearchService";
import { UcMyplanCRSPlnr } from "types/degreePlanningDashboardService";
import { ProfileStudentResponse } from "types/profileStudentIDService";

import { ClassSearchContext } from "./classSearchContext";
import { EField, fetchValues, IQuery } from "./classSearchQuery";
import {
  classSearchInitialState,
  classSearchReducer,
  getFieldList,
  getFilter,
} from "./classSearchReducer";
import LeftNavbar from "./leftNavbar/LeftNavbar";
import MainPage from "./mainPage/MainPage";
import { ClassResponse, ClassResponseUI } from "./types";
import { getCareerList } from "./utils";

const NewClassSearch = () => {
  const [state, dispatch] = useReducer(
    classSearchReducer,
    classSearchInitialState
  );
  const [classResponseUI, setClassResponseUI] = useState<ClassResponseUI>({
    "@odata.context": "",
    "@odata.count": 0,
    "@odata.nextLink": "",
    value: [],
  });
  // TODO: maybe add these states to query reducer?
  const [classResponseStatus, setClassResponseStatus] = useState<
    "pristine" | "loading" | "success" | "error"
  >("pristine");
  const [classFirstLoad, setClassFirstLoad] = useState(false);
  const [classPagination, setClassPagination] = useState<{
    top: number;
    skip: number;
  }>({
    top: 10,
    skip: 0,
  });

  // const [plannerListMemo, setPlannerListMemo] = useState<
  //   Record<string, UcMyplanCRSPlnr[] | undefined>
  // >({});

  const handleSearch = async (top?: number, skip?: number) => {
    const fieldList = getFieldList(
      state.fields,
      state.profile.academicCareerCodeList
    );

    if (!classFirstLoad) return;

    const filter = getFilter(state.fields, fieldList);

    const query: IQuery = {
      select: [
        EField.semesterCode,
        EField.semesterName,
        EField.academicCareerCode,
        EField.subjectCode,
        EField.catalogNumber,
        EField.courseTitle,
        EField.courseDescription,
        EField.creditHours,
      ],
      filter,
      orderby: {
        subjectCode: "asc",
        catalogNumber: "asc",
      },
    };

    if (top !== undefined && skip !== undefined) {
      query.top = top;
      query.skip = skip;
    }

    if (state.freeSearch) {
      query.search = state.freeSearch;
    }

    try {
      setClassResponseStatus("loading");
      const classResponse = (await fetchValues(
        query,
        "value"
      )) as ClassResponse;
      if (!classResponse?.value) return;

      const classResponseUI = {
        ...classResponse,
        value: classResponse.value.map((item) => ({
          ...item,
          isCollapsed: true,
          maxLines: 1,
          plannerStatus: "add",
        })),
      } as ClassResponseUI;

      setClassResponseUI(classResponseUI);
      setClassResponseStatus("success");
      await fetchPlannerList();
    } catch (error) {
      setClassResponseStatus("error");
    }
  };

  const fetchPlannerList = async () => {
    const careerList = ["UGRD", "GRAD", "GR4T", "USAL", "GSAL"];

    const plannerList: UcMyplanCRSPlnr[] = [];
    for (let i = 0; i < careerList.length; i++) {
      const career = careerList[i];

      // if (plannerListMemo[career]) {
      //   plannerList.push(...(plannerListMemo[career] ?? []));
      // } else {
      const coursesResponse = await getPlannerCoursesResponse(career);
      if (!coursesResponse) continue;
      plannerList.push(...coursesResponse);
      // }

      if (plannerList) {
        // setPlannerListMemo((prev) => {
        //   return {
        //     ...prev,
        //     [career]: plannerList,
        //   };
        // });
      }
      dispatch({
        type: "UPDATE_PLANNER_LIST",
        payload: {
          plannerList,
        },
      });

      // update planner status
      setClassResponseUI((prev) => {
        const classUIList = prev.value;
        for (let i = 0; i < classUIList.length; i++) {
          const classItem = classUIList[i];
          classItem.plannerStatus = "add";
          const className = `${classItem.subjectCode} ${classItem.catalogNumber}`;
          if (plannerList) {
            for (let i = 0; i < plannerList.length; i++) {
              const plannerItem = plannerList[i];
              if (plannerItem.COURSE === className) {
                classItem.plannerStatus = "remove";
              }
            }
          }
        }
        return {
          ...prev,
          value: classUIList,
        };
      });
    }
  };

  const fetchStudentProfile = async () => {
    try {
      const [profileStudentResponse, studentTermResponse] = await Promise.all([
        getDuService({
          service: "profileStudentIDService",
          method: "getProfileStudentID",
        }) as Promise<ProfileStudentResponse>,
        getDuService({
          service: "classSearchService",
          method: "getTerms",
        }) as Promise<StudentTermResponse>,
      ]);

      const emplid = profileStudentResponse.Student_ID.EMPLID;
      const campusCode = profileStudentResponse.Student_ID.CAMPUS;
      const academicCareerCodeList = getCareerList(studentTermResponse);
      const academicCareerCode = academicCareerCodeList[0];
      const discountinuedOrAlumni =
        studentTermResponse?.Response?.StudentTerm[0]?.ACAD_CAREER === "";

      const activeTermCodes = studentTermResponse.Response.StudentTerm.map(
        (term) => term.STRM
      );
      const impersonate = sessionStorage.getItem("impersonate") === "true";

      dispatch({
        type: "UPDATE_CAMPUS_CODE",
        payload: {
          campusCode,
        },
      });
      dispatch({
        type: "UPDATE_ACADEMIC_CAREER_CODE",
        payload: {
          academicCareerCode:
            academicCareerCodeList.length > 1
              ? academicCareerCodeList[0]
              : academicCareerCode,
        },
      });
      dispatch({
        type: "UPDATE_STUDENT_PROFILE",
        payload: {
          emplid,
          campusCode,
          academicCareerCode,
          activeTermCodes,
          impersonate,
          academicCareerCodeList,
          discountinuedOrAlumni,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStudentProfile();
  }, []);

  return (
    <ClassSearchContext.Provider
      value={{
        state,
        dispatch,
        classSearchState: {
          classResponseUI,
          setClassResponseUI,
          classFirstLoad,
          setClassFirstLoad,
          classResponseStatus,
          setClassResponseStatus,
          classPagination,
          setClassPagination,
          handleSearch,
        },
      }}
    >
      <div>
        {state && (
          <div className="relative flex">
            <LeftNavbar />
            <MainPage />
          </div>
        )}
      </div>
    </ClassSearchContext.Provider>
  );
};

angularize(NewClassSearch, "newClassSearchReact", angular.module("duApp"), {});

export default NewClassSearch;
