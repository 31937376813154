import React from "react";
import { angularize } from "react-in-angularjs";

const StudentScholarships = () => {
  return (
    <section
      aria-labelledby="Scholarships"
      id=""
      className="border-box -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)] moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)] relative  mb-[1.25rem] mr-[1.75rem] bg-[#ffffff] p-[1.25rem]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        width: "95%",
      }}
    >
      <div className="flex-justify-between">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
            }}
          >
            Scholarships
          </h3>
        </div>
      </div>

      <br />
      <p className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]">
        Explore scholarships you may qualify for and learn about awarding
        processes. Most scholarships require the FAFSA, so submit yours today!
      </p>
      <br />
      <div className="flex-justify-between">
        <div className="text-left">
          <a
            id="scholarshipsAtUMGC"
            target="blank"
            href="https://www.umgc.edu/tuition-financial-assistance/scholarships"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Scholarships at UMGC
          </a>
        </div>
      </div>
    </section>
  );
};

angularize(
  StudentScholarships,
  "studentScholarshipsReact",
  angular.module("duApp"),
  {}
);
export default StudentScholarships;
