import * as Dialog from "@radix-ui/react-dialog";
import Header from "components/Header";
import Link from "components/Link";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import React, { useState } from "react";

interface EditGradingProps {
  trigger: React.ReactNode;
  studentCareer?: string | undefined;
}

const EditGrading: React.FC<EditGradingProps> = ({
  trigger,
  studentCareer,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <ModalWrapper show={isOpen} className="h-[650px] w-[55vw]">
        <div className="mb-6 flex justify-between">
          <Header>Edit Grading</Header>
          <Dialog.Close
            className="border-none text-3xl"
            aria-label="Close"
            autoFocus
          >
            &times;
          </Dialog.Close>
        </div>
        <Paragraph className="mb-4">
          Update your grading preferences. UMGC has four grading methods: graded
          (standard), audit, pass/fail, and satisfactory/D/F. Graded is most
          common and counts towards your degree requirements. In some instances,
          a permission code may be required.{" "}
          <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
            Contact an advisor
          </Link>{" "}
          if you need a permission code.
        </Paragraph>
        <div className="grid grid-cols-3 gap-6">
          <div>
            {studentCareer === "UGRD" && (
              <div>
                <Paragraph>
                  Within the first week of class, you may update your grading
                  preferences for some classes. UMGC has four grading methods:
                  graded (standard), audit, pass/fail, and satisfactory/D/F,
                  which vary depending on the class. Graded is most common and
                  counts towards your degree requirements.{" "}
                </Paragraph>
                <Paragraph>
                  <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                    Contact an advisor
                  </Link>{" "}
                  to fully understand the impact before making any grading basis
                  changes. To make a change, select the new grading basis from
                  the drop-down below.
                </Paragraph>
              </div>
            )}
            {studentCareer !== "UGRD" && (
              <div>
                <Paragraph>
                  Within the first week of class, you may update your grading
                  preferences for some classes. UMGC has four grading methods:
                  graded (standard), audit, pass/fail, and satisfactory/D/F,
                  which vary depending on the class. Graded is most common and
                  counts towards your degree requirements.{" "}
                </Paragraph>
                <Paragraph>
                  Graduate students must{" "}
                  <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                    contact an advisor
                  </Link>{" "}
                  to change the grading basis.
                </Paragraph>
              </div>
            )}
          </div>
          <div>
            <Paragraph className="font-bold">Grading</Paragraph>
            <Paragraph>
              If you would like to change your grading basis, please{" "}
              <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                contact an advisor{" "}
              </Link>
              for assistance.
            </Paragraph>
          </div>
        </div>
        <div className="mt-[30px] flex flex-row justify-end border-0 border-t border-solid border-du-gray pt-[30px]">
          <Dialog.Close className="border border-solid border-du-yellow px-4 py-2 font-bold">
            Cancel
          </Dialog.Close>
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default EditGrading;
