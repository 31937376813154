import React from "react";

interface TrashAlt extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const TrashAlt: React.FC<TrashAlt> = ({ fillColor, ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="-10 0 468 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M336 64l-33.5996 -44.7998c-9.10059 -12.1006 -23.3008 -19.2002 -38.4004 -19.2002h-80c-15.0996 0 -29.2998 7.09961 -38.4004 19.2002l-33.5996 44.7998h-88c-13.2998 0 -24 10.7002 -24 24v2c0 3.2998 2.7002 6 6 6h26v368c0 26.5 21.5 48 48 48h288
        c26.5 0 48 -21.5 48 -48v-368h26c3.2998 0 6 -2.7002 6 -6v-2c0 -13.2998 -10.7002 -24 -24 -24h-88zM184 32h80c5 0 9.7998 2.40039 12.7998 6.40039l19.2002 25.5996h-144l19.2002 -25.5996c3 -4 7.7998 -6.40039 12.7998 -6.40039zM384 464c0 8.7998 -7.2002 16 -16 16
        h-288c-8.7998 0 -16 -7.2002 -16 -16v-368h320v368zM208 420v-264c0 -6.59961 5.40039 -12 12 -12h8c6.59961 0 12 5.40039 12 12v264c0 6.59961 -5.40039 12 -12 12h-8c-6.59961 0 -12 -5.40039 -12 -12zM128 420v-264c0 -6.59961 5.40039 -12 12 -12h8
        c6.59961 0 12 5.40039 12 12v264c0 6.59961 -5.40039 12 -12 12h-8c-6.59961 0 -12 -5.40039 -12 -12zM288 420v-264c0 -6.59961 5.40039 -12 12 -12h8c6.59961 0 12 5.40039 12 12v264c0 6.59961 -5.40039 12 -12 12h-8c-6.59961 0 -12 -5.40039 -12 -12z"
        fill={fillColor ? fillColor : "#ffffff"}
      />
    </svg>
  );
};

export default TrashAlt;
