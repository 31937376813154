import clsx from "clsx";
import MagnifyingGlass from "icons/MagnifyingGlass";
import React, { useContext } from "react";

import { ClassSearchContext } from "../classSearchContext";

const SearchButton = () => {
  const {
    state: {
      profile: { discountinuedOrAlumni },
    },
    classSearchState: {
      handleSearch,
      classFirstLoad,
      setClassFirstLoad,
      classResponseStatus,
      setClassPagination,
    },
  } = useContext(ClassSearchContext);

  return (
    <button
      id="cs-search-button"
      className={clsx(
        "all-revert flex cursor-pointer gap-2 rounded-md border border-solid px-4 py-2 text-sm font-bold",
        classResponseStatus === "loading" || discountinuedOrAlumni
          ? "!cursor-not-allowed border-du-gray bg-du-gray"
          : "border-du-yellow bg-du-yellow"
      )}
      onClick={() => {
        if (!classFirstLoad) setClassFirstLoad(true);
        handleSearch(10, 0);
        setClassPagination({ top: 10, skip: 0 });
      }}
      disabled={classResponseStatus === "loading" || discountinuedOrAlumni}
    >
      <MagnifyingGlass className="h-5 w-5" fillColor="#3c3c43" />
      <span>Search</span>
    </button>
  );
};

export default SearchButton;
