import OutLink from "components/OutLink";
import React from "react";
import { angularize } from "react-in-angularjs";

interface footerPageProps {
  impersonate?: string;
  showingProfileValidation?: boolean;
  shouldNotDisplaySidebar?: boolean;
}
const FooterPage: React.FC<footerPageProps> = ({
  impersonate,
  showingProfileValidation,
  shouldNotDisplaySidebar,
}) => {
  if (
    (showingProfileValidation == false && shouldNotDisplaySidebar == false) ||
    impersonate == "true"
  ) {
    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="cr-p">
            <p className="">
              © {new Date().getFullYear()} University of Maryland Global Campus.
              All rights reserved.
            </p>
          </div>
          <div className="cr-link">
            <ul>
              <li>
                <OutLink
                  className="redirect-link text-du-white text-[12px] after:font-awesome "
                  href="https://www.umgc.edu/terms-and-conditions/accessibility-statement.cfm"
                  iconFillColor="#fff"
                  iconClassName="text-du-white w-[12px]"
                >
                  Accessibility Statement{" "}
                </OutLink>
              </li>
              <li>
                <OutLink
                  className="redirect-link text-du-white text-[12px] after:font-awesome "
                  href="https://www.umgc.edu/terms-and-conditions/index.cfm"
                  iconFillColor="#fff"
                  iconClassName="text-du-white w-[12px]"
                >
                  Terms &amp; Conditions{" "}
                </OutLink>
              </li>
              <li>
                <OutLink
                  className="redirect-link text-du-white text-[12px] after:font-awesome "
                  href="https://www.umgc.edu/terms-and-conditions/privacy-policy.cfm"
                  iconFillColor="#fff"
                  iconClassName="text-du-white w-[12px]"
                >
                  Privacy Policy{" "}
                </OutLink>
              </li>
              <li>
                <OutLink
                  className="redirect-link text-du-white text-[12px] after:font-awesome "
                  href="https://www.umgc.edu/terms-conditions/social-media-guidelines"
                  iconFillColor="#fff"
                  iconClassName="text-du-white w-[12px]"
                >
                  Social Media Guidelines{" "}
                </OutLink>
              </li>
            </ul>
          </div>
          <br />
        </div>
      </footer>
    );
  }

  return <div />;
};

angularize(FooterPage, "footerPageReact", angular.module("duApp"), {
  impersonate: "<",
  showingProfileValidation: "<",
  shouldNotDisplaySidebar: "<",
});
export default FooterPage;
