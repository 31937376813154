import clsx from "clsx";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { GradingEnum, GradingModalState } from "types/editGradingModal";

import ConfirmGrading from "../../shared/editGrading/ConfirmGrading";
import EditGrading from "../../shared/editGrading/EditGrading";
import ClassStatus from "./ClassStatus";
import { ClassUI } from "./Course";
import ConfirmClassRemove from "./modals/ConfirmClassRemove";

interface ClassTableMobileProps {
  career: string;
  classList: ClassUI[];
  handleAddClassToCart: (classItem: ClassUI, grading?: GradingEnum) => void;
  handleRemoveClassFromCart: (classItem: ClassUI) => void;
  impersonate?: "true" | "false";
}

const ClassTableMobile: React.FC<ClassTableMobileProps> = ({
  career,
  classList,
  handleAddClassToCart,
  handleRemoveClassFromCart,
  impersonate,
}) => {
  const [currClassItem, setCurrClassItem] = useState<ClassUI | undefined>(
    undefined
  );
  const [gradingModal, setGradingModal] = useState<GradingModalState>({
    currGrading: GradingEnum.Graded,
    newGrading: GradingEnum.Graded,
    isEditModalOpen: false,
    isConfirmModalOpen: false,
  });

  return (
    <table className="all-revert mx-4 table w-[calc(100%-32px)] border-collapse text-[15px] du-sm:hidden">
      {classList.map((classItem, index) => (
        <tbody
          className="border-[1px] border-solid border-du-light-gray"
          key={`${classItem.CLASS_NBR}-${index}`}
        >
          <tr className="bg-du-light-gray">
            <td className="all-revert px-5 py-[15px]" colSpan={2}>
              {classItem.LOCATION_DESCR}
            </td>
          </tr>
          <tr className="bg-white">
            <td className="all-revert px-5 py-[15px] font-bold">
              Meeting Dates
            </td>
            <td className="all-revert px-5 py-[15px]">
              {DateTime.fromISO(classItem.START_DT).toFormat("M/d/yyyy")}{" "}
              {DateTime.fromISO(classItem.END_DT).toFormat("M/d/yyyy")}
            </td>
          </tr>
          <tr className="bg-du-light-gray">
            <td className="all-revert px-5 py-[15px] font-bold">Instructor</td>
            <td className="all-revert px-5 py-[15px]">
              {classItem.INSTRUCTOR}
            </td>
          </tr>
          <tr className="bg-white">
            <td className="all-revert px-5 py-[15px] font-bold">
              Class Number
            </td>
            <td className="all-revert px-5 py-[15px]">{classItem.CLASS_NBR}</td>
          </tr>
          <tr className="bg-du-light-gray">
            <td className="all-revert px-5 py-[15px] font-bold">Section</td>
            <td className="all-revert px-5 py-[15px]">
              {classItem.CLASS_SECTION}
            </td>
          </tr>
          <tr className="bg-white">
            <td className="all-revert px-5 py-[15px] font-bold">Status</td>
            <td className="all-revert px-5 py-[15px]">
              <ClassStatus classItem={classItem} />
            </td>
          </tr>
          <tr className="bg-du-light-gray">
            <td className="all-revert px-5 py-[15px] font-bold">Notes</td>
            <td className="all-revert px-5 py-[15px]">
              {classItem.CLASS_NOTES}
            </td>
          </tr>
          <tr className="bg-white">
            <td className="all-revert px-5 py-[15px]" colSpan={2}>
              <div className="flex flex-col gap-4">
                {classItem.showClass && classItem.ENRL_STAT_DESC === "Open" && (
                  <button
                    disabled={impersonate === "true"}
                    className={clsx(
                      "border-none px-5 py-[10px] font-bold text-du-black hover:bg-du-yellow",
                      impersonate === "true" && "cursor-not-allowed"
                    )}
                    onClick={() => {
                      setCurrClassItem(classItem);
                      setGradingModal((prev) => ({
                        ...prev,
                        isEditModalOpen: true,
                        currGrading: classItem.gradingBasisCart,
                        newGrading: classItem.gradingBasisCart,
                      }));
                    }}
                  >
                    <span className="mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-du-black after:content-['\f304']" />
                    Edit Grading
                  </button>
                )}

                {classItem.inCart ? (
                  <ConfirmClassRemove
                    trigger={
                      <button
                        disabled={impersonate === "true"}
                        className={clsx(
                          "border-none px-5 py-[10px] font-bold text-du-red hover:bg-du-yellow",
                          impersonate === "true" && "cursor-not-allowed"
                        )}
                      >
                        <span className="mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-du-red after:content-['\f2ed']" />
                        Remove from Cart
                      </button>
                    }
                    action={() => {
                      handleRemoveClassFromCart(classItem);
                    }}
                  />
                ) : (
                  classItem.showClass &&
                  classItem.ENRL_STAT_DESC === "Open" && (
                    <button
                      disabled={impersonate === "true"}
                      className={clsx(
                        "border-none px-5 py-[10px] font-bold text-du-black hover:bg-du-yellow",
                        impersonate === "true" && "cursor-not-allowed"
                      )}
                      onClick={() => {
                        handleAddClassToCart(classItem);
                      }}
                    >
                      <span className="mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-du-black after:content-['\f217']" />
                      Add Class to Cart
                    </button>
                  )
                )}
              </div>
            </td>
          </tr>
        </tbody>
      ))}
      <EditGrading
        career={career}
        gradingModal={gradingModal}
        setGradingModal={setGradingModal}
        actionButton={(isValidOption, handleOnClick) => (
          <button
            className={clsx(
              "border-none px-5 py-[10px] font-bold",
              isValidOption
                ? "bg-du-yellow text-du-black"
                : "bg-du-dark-gray text-white"
            )}
            onClick={() => {
              handleOnClick();
            }}
          >
            {isValidOption ? (
              <span className="mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-du-black after:content-['\f217']" />
            ) : (
              <span className="mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-white after:content-['\f217']" />
            )}
            Add Class to Cart
          </button>
        )}
      />
      <ConfirmGrading
        gradingModal={gradingModal}
        setGradingModal={setGradingModal}
        action={(grading) => {
          if (currClassItem) {
            handleAddClassToCart(currClassItem, grading);
          }
        }}
      />
    </table>
  );
};

export default ClassTableMobile;
