import clsx from "clsx";
import Header from "components/Header";
import Link from "components/Link";
import OutLink from "components/OutLink";
import PageWrapper from "components/PageWrapper";
import Paragraph from "components/Paragraph";
import Tile from "components/Tile";
import useFetch from "hooks/useFetch";
import CaretDown from "icons/CaretDown";
import CaretRight from "icons/CaretRight";
import { DateTime } from "luxon";
import React, { Fragment, useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import {
  Class,
  ClassRegistrationResponse,
} from "types/classRegistrationService";
import { StudentTermResponse } from "types/classSearchService";
import { FetchStatus } from "types/fetch";
import { sendEvents } from "utils/googleTagManager";
import { processTermData } from "utils/termConverterService";

import ClassRegistrationEditServiceImpact from "./ClassRegistrationEditServiceImpact";
import DeepLink from "./DeepLink";
import EditGrading from "./EditGrading";

const ClassSchedule = () => {
  const [angProps, setAngProps] = useState<{
    CENR: string | undefined;
    ACTIVE_STUDENT: string | undefined;
    urlHostName: string | undefined;
    studentCareer: string | undefined;
    domianCheck: string | undefined;
  }>({
    CENR: undefined,
    ACTIVE_STUDENT: undefined,
    urlHostName: undefined,
    studentCareer: undefined,
    domianCheck: undefined,
  });

  const { data: termData, error: termError } = useFetch<StudentTermResponse>({
    service: "classSearchService",
    method: "getTerms",
  });
  const termListData = termData?.Response.StudentTerm;
  const [segmentedCoursesArray, setSegmentedCoursesArray] = useState<Class[][]>(
    []
  );
  const [coursesLength, setCoursesLength] = useState<number>(0);
  const [registrationStatusDescription, setRegistrationStatusDescription] =
    useState<string | undefined>(undefined);

  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(
    FetchStatus.LOADING
  );

  const classTypeDisplay: { [key: string]: string } = {
    ENRL: "Enrolled",
    WL: "Wait List",
    DROP: "Dropped",
    WDRW: "Withdrawn",
  };

  const getClassRegistrations = async () => {
    if (!termListData) return;
    // sort STRM from smallest to largest
    const termPromises = termListData
      .sort((a, b) => Number(a.STRM) - Number(b.STRM))
      .map((term) => {
        return getDuService(
          {
            service: "classRegistrationService",
            method: "getEnrollments",
          },
          `${term.ACAD_CAREER}/${term.STRM}`
        ) as unknown as Promise<ClassRegistrationResponse>;
      });

    try {
      const values = await Promise.all(termPromises);
      const totalCourses: Class[] = [];
      for (let i = 0; i < values.length; i++) {
        const data = values[i].ClassRegistrationResponse;
        const Term_id = values[i].ClassRegistrationResponse.term;
        data.Class.sort((a, b) => {
          const [aYear, aMonth, aDay] = a.START_DT.split("-").map((e) =>
            Number(e)
          );
          const [bYear, bMonth, bDay] = b.START_DT.split("-").map((e) =>
            Number(e)
          );
          return (
            new Date(aYear, aMonth - 1, aDay).getTime() -
            new Date(bYear, bMonth - 1, bDay).getTime()
          );
        });
        for (let j = 0; j < data.Class.length; j++) {
          const classItem = data.Class[j];

          const startDate = +new Date(classItem.START_DT);

          const currDate = +new Date();

          if (startDate > currDate) {
            const diff = Math.abs(startDate - currDate);
            const diffinDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
            if (diffinDays > 21) {
              classItem.DAY_DIFF = 1;
            } else {
              classItem.DAY_DIFF = 0;
            }
          } else {
            classItem.DAY_DIFF = 0;
          }

          if (classItem.CLASS_NBR !== 0 && classItem.SUBJECT !== "") {
            classItem.TERM = processTermData(data.term).split(" ")[0];
            // add ENRL_TYPE, EXPANDED custom props
            classItem.EXPANDED = false;
            classItem.TERM_ID = Term_id;
            switch (classItem.STDNT_ENRL_STATUS) {
              case "E":
                classItem.ENRL_TYPE =
                  classItem.ENRL_DESC === "WDRW" ? "WDRW" : "ENRL";
                break;
              case "W":
                classItem.ENRL_TYPE = "WL";
                break;
              case "D":
                classItem.ENRL_TYPE =
                  classItem.ENRL_DESC !== "WDRW" ? "DROP" : "WDRW";
                break;
            }
            totalCourses.push(classItem);
          }
        }
      }
      sortClassRegistrations(totalCourses);
    } catch (error) {
      setRegistrationStatusDescription(
        "This information is temporarily unavailable. We apologize for any inconvenience and are working to correct the issue. Please try again later."
      );
    } finally {
      setFetchStatus(FetchStatus.IDLE);
    }
  };

  const sortClassRegistrations = (totalCourses: Class[]) => {
    // array of arrays to hold courses segmented by enrollment status
    // [0]: enrolled ENRL
    // [1]: wait listed WL
    // [2]: dropped DROP
    // [3]: withdrawn WDRW

    let segmentedCoursesArray: Class[][] = [[], [], [], []];
    totalCourses.forEach((val) => {
      switch (val.ENRL_TYPE) {
        case "ENRL":
          segmentedCoursesArray[0].push(val);
          break;
        case "WL":
          segmentedCoursesArray[1].push(val);
          break;
        case "DROP":
          segmentedCoursesArray[2].push(val);
          break;
        case "WDRW":
          segmentedCoursesArray[3].push(val);
          break;
      }
    });

    segmentedCoursesArray = segmentedCoursesArray.map((classType) => {
      let prevTerm: string | undefined = "";

      for (let i = 0; i < classType.length; i++) {
        const classItem = classType[i];
        const currTerm = classItem.TERM;
        classItem.TERM = prevTerm !== currTerm ? classItem.TERM : "";
        prevTerm = currTerm;
      }
      return classType;
    });
    setCoursesLength(totalCourses.length);
    setSegmentedCoursesArray(segmentedCoursesArray);
  };

  useEffect(() => {
    getClassRegistrations();
  }, [termListData]);

  useEffect(() => {
    sendEvents();
  }, []);

  useEffect(() => {
    // get some variables from angular
    // access rootScope values from outside angular
    const ngAppEle = angular.element(document.querySelector("[data-ng-app]"));
    const injector = ngAppEle.injector();
    const $rootScope = injector.get("$rootScope");
    setAngProps({
      CENR: $rootScope?.CENR,
      ACTIVE_STUDENT: $rootScope?.ACTIVE_STUDENT,
      urlHostName: $rootScope?.urlHostName,
      studentCareer: $rootScope?.studentCareer,
      domianCheck: $rootScope?.domianCheck,
    });
  }, []);

  const toggleClassItemDetails = (
    classTypeIdx: number,
    classItemIdx: number
  ) => {
    setSegmentedCoursesArray((prev) => {
      const newSegmentedCourseArr = prev.splice(0);
      newSegmentedCourseArr[classTypeIdx][classItemIdx].EXPANDED =
        !newSegmentedCourseArr[classTypeIdx][classItemIdx].EXPANDED;
      return newSegmentedCourseArr;
    });
  };

  // classRegistrationService
  return (
    <PageWrapper className="mx-0">
      <Tile>
        <Header className="mb-4">Class Schedule</Header>
        {/* desktop view */}
        {coursesLength !== 0 && (
          <section className="hidden md:block">
            {segmentedCoursesArray.map((classType, classTypeIdx) => {
              const enrollType = classType[0]?.ENRL_TYPE;
              if (!enrollType) return null;
              const classDisplay = classTypeDisplay[enrollType || ""];
              return (
                <div key={classTypeIdx}>
                  <p className="block text-xl">{classDisplay}</p>
                  <table className="table w-full">
                    <thead>
                      <tr className="table-row bg-white">
                        <th className="table-cell bg-white px-2 py-4 text-left font-secondary text-xl font-thin">
                          Semester
                        </th>
                        {(enrollType === "ENRL" || enrollType === "WL") && (
                          <th className="bg-white"></th>
                        )}
                        <th className="table-cell bg-white px-2 py-4 text-left font-secondary text-xl font-thin">
                          Class
                        </th>
                        <th className="table-cell bg-white px-2 py-4 text-left font-secondary text-xl font-thin">
                          Date and Time
                        </th>
                        <th className="table-cell bg-white px-2 py-4 text-left font-secondary text-xl font-thin">
                          Location
                        </th>
                        <th className="table-cell bg-white px-2 py-4 text-left font-secondary text-xl font-thin">
                          Instructor
                        </th>
                        <th className="table-cell bg-white px-2 py-4 text-left font-secondary text-xl font-thin">
                          Credits
                        </th>
                        <th className="table-cell bg-white px-2 py-4 text-left font-secondary text-xl font-thin">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-[15px]">
                      {classType.map((classItem, classItemIdx) => {
                        const isOdd = classItemIdx % 2 !== 0;
                        return (
                          <Fragment key={classItemIdx}>
                            <tr
                              className={clsx(
                                isOdd ? "bg-du-light-gray" : "bg-white"
                              )}
                            >
                              <td className="table-cell bg-white px-2 py-4">
                                {classItem.TERM}
                              </td>
                              {(enrollType === "ENRL" ||
                                enrollType === "WL") && (
                                <td className="table-cell bg-white px-2 py-4">
                                  <span
                                    className="cursor-pointer text-du-black"
                                    onClick={() =>
                                      toggleClassItemDetails(
                                        classTypeIdx,
                                        classItemIdx
                                      )
                                    }
                                  >
                                    {classItem.EXPANDED ? (
                                      <CaretDown className="h-4 w-4" />
                                    ) : (
                                      <CaretRight className="h-4 w-4" />
                                    )}
                                  </span>
                                </td>
                              )}
                              <td className="table-cell px-2 py-4">
                                {classItem.SUBJECT} {classItem.CATALOG_NBR}{" "}
                                {classItem.COURSE_TITLE_LONG}
                              </td>
                              <td className="table-cell px-2 py-4">
                                {DateTime.fromFormat(
                                  classItem.START_DT,
                                  "yyyy-MM-dd"
                                ).toFormat("MMM. d, yyyy")}{" "}
                                &ndash;{" "}
                                {DateTime.fromFormat(
                                  classItem.END_DT,
                                  "yyyy-MM-dd"
                                ).toFormat("MMM. d, yyyy")}
                              </td>
                              <td className="table-cell px-2 py-4">
                                {classItem.LOCATION_DESCR}
                              </td>
                              <td className="table-cell px-2 py-4">
                                {classItem.INSTRUCTOR}
                              </td>
                              <td className="table-cell px-2 py-4">
                                {classItem.UNITS}
                              </td>
                              <td className="table-cell px-2 py-4">
                                <div>
                                  {Boolean(angProps.CENR) !== true &&
                                    classItem.STDNT_ENRL_STATUS === "D" &&
                                    classItem.ENRL_DESC !== "WDRW" && (
                                      <span>Dropped</span>
                                    )}

                                  {Boolean(angProps.CENR) !== true &&
                                    classItem.ENRL_DESC === "WDRW" && (
                                      <span>Withdrawn</span>
                                    )}

                                  {(classItem.STDNT_ENRL_STATUS === "E" ||
                                    classItem.STDNT_ENRL_STATUS === "W") &&
                                    classItem.ENRL_DESC !== "WDRW" && (
                                      <Fragment>
                                        {Boolean(angProps.CENR) === true ? (
                                          <ClassRegistrationEditServiceImpact
                                            trigger={
                                              <button className="cursor-pointer border-none font-bold text-du-blue before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f304'] hover:underline">
                                                Edit
                                              </button>
                                            }
                                          />
                                        ) : (
                                          <EditGrading
                                            studentCareer={
                                              angProps.studentCareer
                                            }
                                            trigger={
                                              <button className="cursor-pointer border-none font-bold text-du-blue before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f304'] hover:underline">
                                                Edit
                                              </button>
                                            }
                                          />
                                        )}
                                        <DeepLink
                                          domianCheck={angProps.domianCheck}
                                          urlHostName={angProps.urlHostName}
                                          trigger={
                                            <button className="cursor-pointer border-none font-bold text-du-blue before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f2ed'] hover:underline">
                                              Drop
                                            </button>
                                          }
                                        />
                                      </Fragment>
                                    )}
                                </div>
                              </td>
                            </tr>
                            {(enrollType === "ENRL" || enrollType === "WL") && (
                              <tr
                                className={clsx(
                                  isOdd ? "bg-du-light-gray" : "bg-white",
                                  classItem.EXPANDED ? "table-row" : "hidden"
                                )}
                              >
                                <td className="bg-white"></td>
                                <td className="bg-white"></td>
                                <td colSpan={6} className="px-2">
                                  <div className="flex w-full justify-between">
                                    <div>
                                      <p className="mb-4 font-bold">
                                        Class Number
                                      </p>
                                      <p className="mb-4">
                                        {classItem.CLASS_NBR}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="mb-4 font-bold">
                                        Classroom
                                      </p>
                                      <p className="mb-4">
                                        {classItem.MEETING_ROOM}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="mb-4 font-bold">Grading</p>
                                      <p className="mb-4">
                                        {classItem.GRADING_BASIS_DESCR}
                                      </p>
                                    </div>
                                    {parseInt(classItem.CRSE_ID) != 0 &&
                                      classItem.DAY_DIFF === 0 && (
                                        <div>
                                          <p className="mb-3 font-bold"></p>
                                          &nbsp;
                                          <p className="mb-3">
                                            <b>
                                              <OutLink
                                                iconPos="before"
                                                href={`https://api.apidapter.com/v0/adapter/1752?course_external_id=${
                                                  classItem.CRSE_ID
                                                }-0${
                                                  classItem.CRSE_OFFER_NBR
                                                }-${classItem.TERM_ID}-${
                                                  classItem.SESSION_CODE
                                                }-${
                                                  classItem.CLASS_SECTION.split(
                                                    "-"
                                                  )[0]
                                                }`}
                                              >
                                                View Syllabus
                                              </OutLink>
                                            </b>
                                          </p>
                                        </div>
                                      )}
                                    {classItem.DAY_DIFF === 1 && (
                                      <div>
                                        <p className="mb-3">&nbsp; </p>
                                        <p className="mb-3">
                                          <div className="group relative flex">
                                            <span className="cursor-pointer border-none before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f05a']">
                                              View Syllabus
                                            </span>
                                            <span className="absolute -left-[230px] -top-[34px] m-4 mx-auto w-[210px] rounded-md bg-du-light-gray p-2 text-[11px] text-sm opacity-0 transition-opacity group-hover:opacity-100">
                                              Syllabus will be made available 3
                                              weeks prior to class start date
                                            </span>
                                          </div>
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </section>
        )}
        {/* mobile view */}
        {coursesLength !== 0 && (
          <section className="block space-y-4 md:hidden">
            {segmentedCoursesArray.map((classType, classTypeIdx) => {
              const enrollType = classType[0]?.ENRL_TYPE;
              const classDisplay = classTypeDisplay[enrollType || ""];
              return (
                <div key={classTypeIdx}>
                  <p className="block text-xl">{classDisplay}</p>

                  <ul className="space-y-4">
                    {classType.map((classItem, classItemIdx) => {
                      return (
                        <li key={classItemIdx}>
                          <p className="table-cell bg-white px-2 py-4">
                            {classType[classItemIdx]?.TERM}
                          </p>
                          <div className="flex items-center gap-2 bg-du-gray p-4">
                            <span
                              className="cursor-pointer text-du-black"
                              onClick={() =>
                                toggleClassItemDetails(
                                  classTypeIdx,
                                  classItemIdx
                                )
                              }
                            >
                              {classItem.EXPANDED ? (
                                <CaretDown className="h-4 w-4" />
                              ) : (
                                <CaretRight className="h-4 w-4" />
                              )}
                            </span>
                            <p>
                              {classItem.SUBJECT} {classItem.CATALOG_NBR}{" "}
                              {classItem.COURSE_TITLE_LONG}
                            </p>
                          </div>
                          <div
                            className={clsx(
                              "grid grid-cols-2 grid-rows-3 gap-4 p-4",
                              classItem.EXPANDED ? "" : "hidden"
                            )}
                          >
                            <div>
                              <p className="font-bold">Class</p>
                              <p>
                                {classItem.SUBJECT} {classItem.CATALOG_NBR}{" "}
                                {classItem.COURSE_TITLE_LONG}
                              </p>
                            </div>
                            <div>
                              <p className="font-bold">Date and Time</p>
                              <p>
                                {DateTime.fromFormat(
                                  classItem.START_DT,
                                  "yyyy-MM-dd"
                                ).toFormat("MMM. d, yyyy")}{" "}
                                &ndash;{" "}
                                {DateTime.fromFormat(
                                  classItem.END_DT,
                                  "yyyy-MM-dd"
                                ).toFormat("MMM. d, yyyy")}
                              </p>
                            </div>
                            <div>
                              <p className="font-bold">Location</p>
                              <p>{classItem.LOCATION_DESCR}</p>
                            </div>
                            <div>
                              <p className="font-bold">Instructor</p>
                              <p>{classItem.INSTRUCTOR}</p>
                            </div>
                            <div>
                              <p className="font-bold">Credits</p>
                              <p>{classItem.UNITS}</p>
                            </div>
                            <div>
                              <p className="font-bold">Action</p>
                              <div>
                                {Boolean(angProps.CENR) !== true &&
                                  classItem.STDNT_ENRL_STATUS === "D" &&
                                  classItem.ENRL_DESC !== "WDRW" && (
                                    <span>Dropped</span>
                                  )}

                                {Boolean(angProps.CENR) !== true &&
                                  classItem.ENRL_DESC === "WDRW" && (
                                    <span>Withdrawn</span>
                                  )}

                                {(classItem.STDNT_ENRL_STATUS === "E" ||
                                  classItem.STDNT_ENRL_STATUS === "W") &&
                                  classItem.ENRL_DESC !== "WDRW" && (
                                    <Fragment>
                                      {Boolean(angProps.CENR) === true ? (
                                        <ClassRegistrationEditServiceImpact
                                          trigger={
                                            <button className="cursor-pointer border-none font-bold text-du-blue before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f304'] hover:underline">
                                              Edit
                                            </button>
                                          }
                                        />
                                      ) : (
                                        <EditGrading
                                          studentCareer={angProps.studentCareer}
                                          trigger={
                                            <button className="cursor-pointer border-none font-bold text-du-blue before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f304'] hover:underline">
                                              Edit
                                            </button>
                                          }
                                        />
                                      )}
                                      <br />
                                      <DeepLink
                                        domianCheck={angProps.domianCheck}
                                        urlHostName={angProps.urlHostName}
                                        trigger={
                                          <button className="cursor-pointer border-none font-bold text-du-blue before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f2ed'] hover:underline">
                                            Drop
                                          </button>
                                        }
                                      />
                                    </Fragment>
                                  )}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </section>
        )}
        {/* errors view */}
        {coursesLength === 0 && fetchStatus === FetchStatus.IDLE && (
          <section className="space-y-2">
            {/* termsStatusDescription */}
            {termError && (
              <Paragraph>
                This information is temporarily unavailable. We apologize for
                any inconvenience and are working to correct the issue. Please
                try again later.
              </Paragraph>
            )}
            {registrationStatusDescription && (
              <Paragraph>
                We are unable to display schedule information for you. Please{" "}
                <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                  contact an advisor
                </Link>{" "}
                for assistance.
              </Paragraph>
            )}
            {/* {!angProps.ACTIVE_STUDENT && (
              <Paragraph>
                You are not registered for classes. If you would like to
                register, please contact us for assistance.
              </Paragraph>
            )}
            {angProps.ACTIVE_STUDENT && (
              <Paragraph>
                You are not registered for classes.{" "}
                <Link href="#/myNextCourse">Enroll now</Link> or contact us for
                assistance.
              </Paragraph>
            )}
            {!angProps.CENR &&
              termListData &&
              termListData[0].ACAD_CAREER === "" && (
                <Paragraph>
                  You are not registered for classes. To register, please
                  contact us for assistance.
                </Paragraph>
              )}
            {angProps.CENR && (
              <Paragraph>
                There is a hold on your account that prevents you from
                registering. Please contact us for assistance.
              </Paragraph>
            )} */}
          </section>
        )}
      </Tile>
    </PageWrapper>
  );
};

angularize(ClassSchedule, "classScheduleReact", angular.module("duApp"), {});

export default ClassSchedule;
