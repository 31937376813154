import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";

import CareerServices from "./pages/CareerServices";
import Chatbot from "./pages/chatbot/Chatbot";
import ClassSchedule from "./pages/classSchedule/ClassSchedule";
import ClassNumber from "./pages/classSearch/ClassNumber";
import Course from "./pages/classSearch/course/Course";
import CreditHours from "./pages/classSearch/CreditHours";
import LocationField from "./pages/classSearch/location/LocationField";
import SubjectField from "./pages/classSearch/subject/SubjectField";
import WeekDays from "./pages/classSearch/WeekDays";
import DirectDeposit from "./pages/finances/directDeposit";
import FafsaStatus from "./pages/finances/fafsaStatus";
import FinancialAid from "./pages/finances/financialAid";
import FinancialResources from "./pages/finances/financialResources";
import FinToDescrip from "./pages/finances/FinToDescrip";
import FinToDo from "./pages/finances/finToDo";
import FinToDoDetail from "./pages/finances/finToDoDetails";
import MilitaryEducationBenefits from "./pages/finances/militaryEducationBenefits";
import StudentAccount from "./pages/finances/studentAccount";
import StudentAccountCenter from "./pages/finances/studentAccountCenter";
import StudentScholarships from "./pages/finances/studentScholarships";
import VeteranBenefits from "./pages/finances/veteranBenefits";
import HeaderImage from "./pages/HeaderImage";
import FooterBottomHalf from "./pages/home/footer/FooterBottomHalf";
import FooterTopHalf from "./pages/home/footer/FooterTopHalf";
import RadioGroup from "./pages/militaryInformation/MilitaryLocation";
import MilitaryLocationDate from "./pages/militaryInformation/militaryLocationDate";
import NewClassSearch from "./pages/newClassSearch/NewClassSearch";
import EffectiveDate from "./pages/profile/EffectiveDate";
import MilitaryHeader from "./pages/profile/MilitaryHeader";
import ShoppingCart from "./pages/shoppingCart/ShoppingCart";
import StudentForms from "./pages/StudentsForms";
import StudentSupport from "./pages/StudentSupport";
const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <CareerServices />
      <StudentSupport />
      <NewClassSearch />
      <ClassSchedule />
      <CreditHours />
      <SubjectField />
      <FinancialResources />
      <FinToDo />
      <LocationField />
      <Chatbot />
      <HeaderImage />
      <ShoppingCart />
      <Course />
      <RadioGroup />
      <DirectDeposit />
      <ClassNumber />
      <StudentForms />
      <StudentAccountCenter />
      <StudentScholarships />
      <MilitaryEducationBenefits />
      <VeteranBenefits />
      <MilitaryHeader />
      <MilitaryLocationDate />
      <FinancialAid />
      <EffectiveDate />
      <WeekDays></WeekDays>
      <FooterTopHalf />
      <FafsaStatus />
      <FooterBottomHalf />
      <StudentAccount />
      <FinToDoDetail />
      <FinToDescrip />
    </React.StrictMode>
  );
}
