import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import Check from "icons/Check";
import React from "react";

interface CheckboxProps {
  id?: string;
  value: boolean;
  ariaLabelledby?: string;
  onChange: (value: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  value,
  ariaLabelledby,
  onChange,
}) => {
  return (
    <CheckboxPrimitive.Root
      id={id}
      aria-labelledby={ariaLabelledby}
      value={value ? "on" : "off"}
      checked={value}
      onCheckedChange={(newValue) => {
        if (typeof newValue === "boolean") {
          onChange(newValue);
        }
      }}
      className="all-revert flex h-[20px] w-[20px] items-center justify-center border border-solid data-[state=checked]:border-du-skyblue data-[state=unchecked]:border-du-black data-[state=checked]:bg-du-skyblue data-[state=unchecked]:bg-white"
    >
      <CheckboxPrimitive.Indicator>
        <Check className="h-[18px] w-[18px] data-[state=checked]:block data-[state=unchecked]:hidden" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
};

export default Checkbox;
