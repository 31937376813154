import React from "react";
import { ClassCS } from "types/classSearchService";

interface ClassStatusProps {
  classItem: ClassCS;
}

const ClassStatus: React.FC<ClassStatusProps> = ({ classItem }) => {
  switch (classItem.ENRL_STAT_DESC) {
    case "Open":
      return (
        <div className="all-revert flex items-center">
          <div
            aria-label={`${classItem.AVAILABLE_SEATS} Seats Available`}
            className="all-revert mr-2 inline-block h-[15px] min-w-[15px] rounded-full bg-[#108A27] px-0.5 text-[10px] font-semibold text-white"
          >
            <div className="all-revert flex h-full w-full items-center justify-center">
              {classItem.AVAILABLE_SEATS}
            </div>
          </div>
          Seats Available
        </div>
      );
    case "Closed":
      return (
        <div className="all-revert flex items-center">
          <div
            aria-label="Closed"
            className="all-revert mr-2 inline-block h-[15px] w-[15px] rounded-full bg-[#8A1410]"
          ></div>
          Closed
        </div>
      );
    case "Wait List":
      return (
        <div className="all-revert flex items-center">
          <div
            aria-label="Wait List"
            className="all-revert mr-2 inline-block h-[15px] w-[15px] rounded-full bg-[#FCB319]"
          ></div>
          Wait List
        </div>
      );
    default:
      return null;
  }
};

export default ClassStatus;
