export const processTermData = (termData?: string) => {
  if (termData) {
    if (termData.length > 4) {
      return termData;
    }
    if (termData === "0000") {
      return "Current Term";
    }
    const rawYear = termData.substring(0, 3),
      rawSeason = termData.substring(rawYear.length, termData.length);
    return getSeasonName(rawSeason) + " " + getYear(rawYear);
  }
  return "";
};
const getYear = (rawYear: string) => {
  const parsedYear = parseInt(rawYear);
  let year = "";
  if (parsedYear > 111 && parsedYear <= 199) {
    year = rawYear.substring(0, 1) + "9" + rawYear.substring(1, 3);
  }
  if (parsedYear > 199) {
    year = rawYear.substring(0, 1) + "0" + rawYear.substring(1, rawYear.length);
  }
  return year;
};
const getSeasonName = (rawSeason: string) => {
  const parsedSeason = parseInt(rawSeason);
  let seasonName = "";
  if (parsedSeason === 1) {
    seasonName = "Winter";
  } else if (parsedSeason === 2) {
    seasonName = "Spring";
  } else if (parsedSeason === 5) {
    seasonName = "Summer";
  } else if (parsedSeason === 8) {
    seasonName = "Fall";
  }
  return seasonName;
};

export const termDescrToNumberCnvt = (termData: string) => {
  let semester = 0;
  let year = "";
  const array = termData.split(" ");
  const rawYear = array[0];
  const rawSemester = array[1];

  const parsedYear = parseInt(rawYear);

  if (parsedYear > 1111 && parsedYear <= 9999) {
    year = rawYear.substring(0, 1) + rawYear.substring(2, 4);
  }

  if (rawSemester != undefined && rawSemester != "") {
    if (rawSemester == "Winter") {
      semester = 1;
    } else if (rawSemester == "Spring") {
      semester = 2;
    } else if (rawSemester == "Summer") {
      semester = 5;
    } else if (rawSemester == "Fall") {
      semester = 8;
    }
  }

  return year + semester;
};

export const reverseTermDesc = (termData: string) => {
  const [semester, year] = termData.split(" ");

  return semester + " " + year;
};
