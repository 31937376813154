import Header from "components/Header";
import React, { useContext } from "react";

import { ClassSearchContext } from "../classSearchContext";
import GuidedSearch from "../guidedSearch/GuidedSearch";
import ClassField from "./fields/ClassField";
import ClassFormatField from "./fields/ClassFormatField";
import LocationField from "./fields/LocationField";
import StartDateField from "./fields/startDateField/StartDateField";
import SubjectField from "./fields/SubjectField";
import ResetAllButton from "./ResetAllButton";
import ClassTable from "./results/ClassTable";
import SearchButton from "./SearchButton";

export const CLASS_PAGE_SIZE = 10;

const MainPage = () => {
  const {
    state: {
      profile: { discountinuedOrAlumni },
    },
  } = useContext(ClassSearchContext);

  return (
    <div className="w-full p-6">
      <div className="bg-white px-6 py-6 shadow-xl">
        <div className="mb-6">
          <div className="space-y-2">
            <Header>Class Search</Header>
            {discountinuedOrAlumni && (
              <p className="text-du-red">
                You do not meet the criteria for enrollment, please contact an
                advisor for assistance.
              </p>
            )}
            <p>
              Search classes to add them to your shopping cart or degree
              planner. Search is limited to semesters that are currently open
              for registration.
            </p>
          </div>
          {/* <GuidedSearch
            trigger={
              <button className="border border-solid border-black px-5 py-2.5 font-bold">
                Guided Search
              </button>
            }
          /> */}
        </div>
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <ClassFormatField />
            <StartDateField />
            <LocationField />
            <SubjectField />
            <div className="col-span-1 md:col-span-2">
              <ClassField />
            </div>
            <div className="flex justify-center gap-1.5 md:col-[3] md:row-[3] md:items-end md:justify-end">
              <ResetAllButton />
              <SearchButton />
            </div>
          </div>
          <div>
            <ClassTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
