import Header from "components/Header";
import OutLink from "components/OutLink";
import PageWrapper from "components/PageWrapper";
import Paragraph from "components/Paragraph";
import Tile from "components/Tile";
import React, { useEffect } from "react";
import { angularize } from "react-in-angularjs";
import { sendEvents } from "utils/googleTagManager";

const StudentForms = () => {
  useEffect(() => {
    sendEvents();
  }, []);

  return (
    <PageWrapper className="mx-[0] grid grid-cols-1 gap-6">
      <Tile>
        <Header className="mb-4">Student Forms</Header>
        <Paragraph>
          The following forms are available for students who need them and are
          not required to be completed by all students.
        </Paragraph>
      </Tile>
      <Tile className="space-y-6">
        <Paragraph>
          <OutLink href="https://forms-ds.umgc.edu/ferpaRelease">
            Student Records Release Form
          </OutLink>{" "}
          This form is for students who wish to disclose their student records
          to a third party. This would include spouses, parents, other
          relatives, and employers.{" "}
          <OutLink href="https://www.umgc.edu/current-students/finances/financial-aid/financial-aid-policies/ferpa">
            Learn More
          </OutLink>
        </Paragraph>
        <Paragraph>
          <OutLink href="https://forms-ds.umgc.edu/ferpaDirectory">
            FERPA Opt In/Opt Out and Confidential Hold Request Form
          </OutLink>{" "}
          This form is for current and former UMGC students to restrict
          Directory Information from being disclosed or released once initiated
          on a student's account. The form can also be completed to enter a
          Confidential Hold on a student's UMGC account to restrict all
          Directory Information from being released.
        </Paragraph>
        <Paragraph>
          <OutLink href="https://forms-ds.umgc.edu/enrollmentVerification">
            UMGC Enrollment Verification Request Form
          </OutLink>{" "}
          This form is used to verify a current UMGC student's enrollment
          status. The letter will provide dates of attendance and whether the
          student is enrolled full-time/part-time (or less than half-time based
          on the semester). The verification can be provided directly to the
          student, a third-party organization, or a company.
          <Paragraph className="font-bold">
            (Request can only be provided for the current semester or one
            semester back)
          </Paragraph>
        </Paragraph>
        <Paragraph>
          <OutLink href="https://forms-ds.umgc.edu/officialGradeReport">
            UMGC Official Grade Report Request Form
          </OutLink>{" "}
          This form is used to provide grades for current UMGC students for a
          specific semester. The grade report can be provided directly to the
          student, a third-party organization, or a company.
          <Paragraph className="font-bold">
            (Request can only be provided for the current semester or one
            semester back)
          </Paragraph>
        </Paragraph>
      </Tile>
    </PageWrapper>
  );
};

angularize(StudentForms, "studentFormsReact", angular.module("duApp"));

export default StudentForms;
