import * as Dialog from "@radix-ui/react-dialog";
import Header from "components/Header";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import React, { useState } from "react";

interface DeepLinkProps {
  trigger: React.ReactNode;
  urlHostName?: string | undefined;
  domianCheck?: string | undefined;
}

const DeepLink: React.FC<DeepLinkProps> = ({
  trigger,
  urlHostName,
  domianCheck,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleRedirect = (url: string) => {
    setIsOpen(false);
    window.open(url, "_blank");
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <ModalWrapper show={isOpen} className="h-[280px] max-h-[280px] w-[55vw]">
        <div className="mb-6 flex justify-between">
          <Header>A new tab will open to display this page</Header>
          <Dialog.Close className="border-none text-3xl" aria-label="Close">
            &nbsp;&nbsp;&nbsp;&times;
          </Dialog.Close>
        </div>
        <Paragraph>
          When you are finished, you can continue your session by returning to
          this tab.
        </Paragraph>
        <div className="mt-[30px] flex flex-row justify-end gap-4 border-0 border-t border-solid border-du-gray pt-[30px]">
          <Dialog.Close className="border border-solid border-du-yellow px-4 py-2 font-bold">
            Cancel
          </Dialog.Close>
          {urlHostName === "portalqat.umgc.edu" && (
            <button
              onClick={() => {
                handleRedirect(
                  "https://awscsint-sso.psdev.umgc.edu/psp/csintsso/EMPLOYEE/SA/c/SSR_STUDENT_FL.SSR_DROP_CLASS_FL.GBL"
                );
              }}
              className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
            >
              OK
            </button>
          )}
          {urlHostName === "portalstg.umgc.edu" && (
            <button
              onClick={() => {
                handleRedirect(
                  "https://awscsuat-sso.psdev.umgc.edu/psp/csuatsso/EMPLOYEE/SA/c/SSR_STUDENT_FL.SSR_DROP_CLASS_FL.GBL"
                );
              }}
              className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
            >
              OK
            </button>
          )}
          {domianCheck === "sso.umgc.edu" && (
            <button
              onClick={() => {
                handleRedirect(
                  "https://pshr.ps.umgc.edu/psp/uuccsprd/EMPLOYEE/SA/c/SSR_STUDENT_FL.SSR_DROP_CLASS_FL.GBL"
                );
              }}
              className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
            >
              OK
            </button>
          )}
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default DeepLink;
