import clsx from "clsx";
import Autocomplete from "components/autocomplete/Autocomplete";
import useFetch from "hooks/useFetch";
import List from "icons/List";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import { SubjectValue, SubjectValueResponse } from "types/classSearchService";
import { GenericItem } from "types/select";

import { LocationValueCustom } from "../location/LocationField";
import SubjectListModal from "./SubjectListModal";

export interface SubjectValueCustom extends GenericItem, SubjectValue {}

const SubjectField = () => {
  const [subject, setSubject] = useState<SubjectValueCustom>({
    $id: "",
    $name: "",
    Code: "",
    Description: "",
    Name: "",
    short_description: "",
  });
  const [subjectError, setSubjectError] = useState(false);
  const [subjectValuesCustom, setSubjectValuesCustom] = useState<
    SubjectValueCustom[]
  >([]);

  const { data: subjectData } = useFetch<SubjectValueResponse>({
    service: "classSearchService",
    method: "getSubjectList",
  });

  // temp code while class seach is converted to react
  const handleFieldError = () => {
    const location = JSON.parse(
      document.getElementById("react-class-search-location")?.textContent ?? ""
    ) as LocationValueCustom;
    const subject = JSON.parse(
      document.getElementById("react-class-search-subject")?.textContent ?? ""
    ) as LocationValueCustom;
    setSubjectError(subject.$id === "" && location.$id === "");
  };

  useEffect(() => {
    const $submitButton = document.getElementById("class-search-submit-button");
    if (!$submitButton) return;

    const setSubjectEvent = (e: any) => {
      setSubject(e.detail);
    };
    const setSubjectErrorEvent = (e: any) => {
      setSubjectError(e.detail);
    };

    $submitButton.addEventListener("click", handleFieldError);
    window.addEventListener("setSubjectEvent", setSubjectEvent);
    window.addEventListener("setSubjectErrorEvent", setSubjectErrorEvent);
    return () => {
      $submitButton.removeEventListener("click", handleFieldError);
      window.removeEventListener("setSubjectEvent", setSubjectEvent);
      window.removeEventListener("setSubjectErrorEvent", setSubjectErrorEvent);
    };
  }, []);

  useEffect(() => {
    if (subject.$id !== "") {
      setSubjectError(false);
      window.dispatchEvent(
        new CustomEvent("setLocationErrorEvent", { detail: false })
      );
    }
  }, [subject]);

  useEffect(() => {
    if (subjectData) {
      setSubjectValuesCustom(
        subjectData.subjectValues.map((s) => ({
          ...s,
          $id: s.Code,
          $name: `${s.Code} - ${s.Description}`,
        }))
      );
    }
  }, [subjectData]);
  // end temp

  return (
    <div>
      <label
        htmlFor="cs-subject"
        className={clsx(
          "mb-2.5 inline-block text-[15px] font-bold",
          subjectError ? "text-du-red" : "text-du-black"
        )}
      >
        Subject
      </label>
      <Autocomplete
        className={clsx(
          "box-border h-[40px] w-full border-[1px] border-solid pl-2.5 text-[15px] font-medium",
          subjectError ? "border-du-red" : "border-[#ADADAD]"
        )}
        currItem={subject}
        items={subjectValuesCustom}
        filterItems={(item, inputVal) => {
          if (item.$name.toLowerCase().includes(inputVal.toLowerCase())) {
            return true;
          }
          return false;
        }}
        updateItem={(item) => {
          setSubject(item);
        }}
        icon={
          <SubjectListModal
            trigger={
              <button
                className={clsx(
                  "flex h-[38px] w-[40px] items-center justify-center border-none",
                  subjectError ? "bg-du-red" : "bg-du-skyblue"
                )}
              >
                <List className="h-5 w-5" fillColor="white" />
              </button>
            }
            setSubject={setSubject}
            subjectValues={subjectValuesCustom}
          />
        }
        listAll={false}
        inputId="cs-subject"
        iconAction={false}
      />
      {subjectError && (
        <div className="mt-2.5 text-xs text-du-red">
          Subject or Location is required
        </div>
      )}
      <span className="hidden" id="react-class-search-subject">
        {JSON.stringify(subject)}
      </span>
    </div>
  );
};

angularize(SubjectField, "subjectFieldReact", angular.module("duApp"), {});

export default SubjectField;
