import * as Dialog from "@radix-ui/react-dialog";
import * as Tabs from "@radix-ui/react-tabs";
import Header from "components/Header";
import ModalWrapper from "components/ModalWrapper";
import React, { useMemo, useState } from "react";

import { SubjectValueCustom } from "./SubjectField";

interface SubjectFieldModalProps {
  trigger: React.ReactNode;
  setSubject: React.Dispatch<React.SetStateAction<SubjectValueCustom>>;
  subjectValues: SubjectValueCustom[];
}

const SubjectListModal: React.FC<SubjectFieldModalProps> = ({
  trigger,
  setSubject,
  subjectValues,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const letterRanges = useMemo(
    () => ({
      "A-B": subjectValues
        .filter((s) => /^[A-B]/i.test(s.Code))
        .sort((a, b) => a.Code.localeCompare(b.Code)),
      C: subjectValues
        .filter((s) => /^[C]/i.test(s.Code))
        .sort((a, b) => a.Code.localeCompare(b.Code)),
      "D-E": subjectValues
        .filter((s) => /^[D-E]/i.test(s.Code))
        .sort((a, b) => a.Code.localeCompare(b.Code)),
      "F-G": subjectValues
        .filter((s) => /^[F-G]/i.test(s.Code))
        .sort((a, b) => a.Code.localeCompare(b.Code)),
      "H-L": subjectValues
        .filter((s) => /^[H-L]/i.test(s.Code))
        .sort((a, b) => a.Code.localeCompare(b.Code)),
      "M-R": subjectValues
        .filter((s) => /^[M-R]/i.test(s.Code))
        .sort((a, b) => a.Code.localeCompare(b.Code)),
      "S-Z": subjectValues
        .filter((s) => /^[S-Z]/i.test(s.Code))
        .sort((a, b) => a.Code.localeCompare(b.Code)),
    }),
    [subjectValues]
  );

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <ModalWrapper
        show={isOpen}
        className="!h-[500px] max-h-[80vh] w-[660px] max-w-[90vw]"
      >
        <div className="mb-6 flex justify-between">
          <Header className="text-[30px]">Select a Subject</Header>
          <Dialog.Close className="border-none text-3xl" aria-label="Close">
            &times;
          </Dialog.Close>
        </div>
        <Tabs.Root defaultValue="A-B">
          <Tabs.List className="mb-4 space-x-6">
            {Object.keys(letterRanges).map((r) => (
              <Tabs.Trigger
                tabIndex={0}
                key={r}
                value={r}
                className="border-none px-0.5 font-bold text-du-blue data-[state=active]:rounded-sm data-[state=active]:bg-blue-100"
              >
                {r}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {Object.entries(letterRanges).map(([key, subjectValues]) => (
            <Tabs.Content key={key} value={key}>
              <ul className="grid grid-cols-2 gap-4">
                {subjectValues.map((s) => (
                  <li key={s.$id} className="mb-0 font-bold">
                    <span
                      tabIndex={0}
                      onClick={() => {
                        setSubject(s);
                        setIsOpen(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setSubject(s);
                          setIsOpen(false);
                        }
                      }}
                      className="cursor-pointer text-du-blue hover:underline"
                    >
                      {s.$name}
                    </span>
                  </li>
                ))}
              </ul>
            </Tabs.Content>
          ))}
        </Tabs.Root>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default SubjectListModal;
