import "./style.css";

import clsx from "clsx";
import Alert, { AlertType } from "components/alert/Alert";
import Header from "components/Header";
import PageWrapper from "components/PageWrapper";
import Paragraph from "components/Paragraph";
import Select from "components/select/Select";
import Tile from "components/Tile";
import CaretDown from "icons/CaretDown";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import {
  EnrollmentRespdatum,
  EnrollmentResponse,
} from "types/classRegistrationService";
import {
  CartDetail,
  ShoppingCartResponse,
  StudentID,
  StudentIDResponse,
  StudentTerm,
  StudentTermResponse,
} from "types/classSearchService";
import { FetchStatus } from "types/fetch";
import { sendEvents, sendEventsCustomPayload } from "utils/googleTagManager";

import CartList from "./CartList";
import RegistrationSummary from "./RegistrationSummary";

export interface CartItemUI extends CartDetail {
  registerClass: boolean;
}

export interface TermUI extends Partial<StudentTerm> {
  $id: string;
  $name: string;
}

interface ShoppingCartProps {
  impersonate?: "true" | "false";
}

const ShoppingCart: React.FC<ShoppingCartProps> = ({ impersonate }) => {
  const [alert, setAlert] = useState<AlertType>({
    message: "",
    show: false,
    type: "success",
  });
  const [idCard, setIdCard] = useState<StudentID | undefined>(undefined);
  const [terms, setTerms] = useState<TermUI[]>([]);
  const [term, setTerm] = useState<TermUI>({
    $id: "",
    $name: "",
  });
  const [cartItems, setCartItems] = useState<CartItemUI[]>([]);
  const [enrollmentRespdata, setEnrollmentRespdata] = useState<
    EnrollmentRespdatum[]
  >([]);
  const [registerStatus, setRegisterStatus] = useState<FetchStatus>(
    FetchStatus.IDLE
  );

  const fetchProfileIDCard = async () => {
    const idCardResponse = (await getDuService({
      service: "classSearchService",
      method: "getCampusCode",
    })) as StudentIDResponse;
    setIdCard(idCardResponse.Student_ID);
  };

  const fetchTerms = async () => {
    const studentTermResponse = (await getDuService({
      service: "classSearchService",
      method: "getTerms",
    })) as StudentTermResponse;

    const terms = studentTermResponse.Response.StudentTerm.sort(
      (a, b) => Number(b.STRM) - Number(a.STRM)
    ).map((item) => {
      let displayName = item.TERM_DESCRIPTION;
      if (/\d{4}\s\w+/.test(item.TERM_DESCRIPTION)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const [, year, term] = /(\d{4})\s(\w+)/.exec(item.TERM_DESCRIPTION)!;
        displayName = `${term} ${year}`;
      }
      return {
        ...item,
        $id: item.STRM,
        $name: displayName,
      };
    });

    const termsUnique: typeof terms = [];
    for (let i = 0; i < terms.length; i++) {
      const term = terms[i];
      if (!termsUnique.find((t) => t.$id === term.$id)) {
        termsUnique.push(term);
      }
    }

    setTerms(termsUnique);
  };

  const fetchShoppingCartDetails = async (strm: string, career: string) => {
    const url = `${strm}/${career}`;
    const shoppingCartResponse = await (getDuService(
      {
        service: "classSearchService",
        method: "getShoppingCart",
      },
      url
    ) as Promise<ShoppingCartResponse>);

    const cartDetails = shoppingCartResponse?.ShoppingCartResp?.CartDetails;
    if (cartDetails && cartDetails[0].ClassNbr !== 0) {
      setCartItems(
        cartDetails.map((item) => ({ ...item, registerClass: true }))
      );
    } else if (cartDetails && cartDetails[0].ClassNbr === 0) {
      setCartItems([]);
    }
  };

  const handleRegisterForClasses = async () => {
    if (impersonate === "true") return;
    if (!idCard) return;

    const payload = {
      EnollmentRqst: {
        EmplId: idCard.EMPLID,
        Term: term.STRM,
        AcadCareer: term.ACAD_CAREER,
        Institution: term.INSTITUTION,
        Enrollmentdata: cartItems
          .filter((cartItem) => cartItem.registerClass)
          .map((cartItem) => ({
            Emplid: idCard.EMPLID,
            ClassNbr: cartItem.ClassNbr,
            EnrlAction: "E",
            GradingBasis: cartItem.GradingBasis,
            PermNbr: 0,
          })),
      },
    };

    const handleResponse = (enrollmentRespdata: EnrollmentRespdatum[]) => {
      const classes = cartItems
        .filter((cartItem) => cartItem.registerClass)
        .map((cartItem) => {
          const cartItemResp = enrollmentRespdata.find(
            (item) => item.ClassNbr === String(cartItem.ClassNbr)
          );
          return {
            class_name: cartItem.ClassName,
            class_number: cartItem.ClassNbr,
            semester: term.STRM,
            status: `${
              cartItemResp?.RespStatus === "200" ? "success" : "failed"
            } ${cartItemResp?.RespMsg}`,
          };
        });
      const dataLayerPayload = {
        event: "registration",
        classes,
        user_id: idCard.EMPLID,
      };
      sendEventsCustomPayload(dataLayerPayload);
      setEnrollmentRespdata(enrollmentRespdata);
    };

    try {
      setRegisterStatus(FetchStatus.LOADING);
      const result = (await getDuService(
        {
          service: "classRegistrationService",
          method: "postEnrollmentData",
        },
        payload
      )) as EnrollmentResponse;

      if (result?.EnrollmentResp?.EnrollmentRespdata) {
        handleResponse(result.EnrollmentResp.EnrollmentRespdata);
      }
    } catch (error) {
      const result = (error as any).data as EnrollmentResponse;
      if (result?.EnrollmentResp?.EnrollmentRespdata) {
        handleResponse(result.EnrollmentResp.EnrollmentRespdata);
      }
      console.log(error);
    } finally {
      // send event to angular
      window.dispatchEvent(new CustomEvent("updateCartCountEvent"));
      setRegisterStatus(FetchStatus.IDLE);
    }
  };

  useEffect(() => {
    fetchProfileIDCard();
    fetchTerms();
    sendEvents();
  }, []);

  useEffect(() => {
    if (term.STRM && term.STRM !== "" && term.ACAD_CAREER) {
      fetchShoppingCartDetails(term.STRM, term.ACAD_CAREER);
    }
  }, [term]);

  return (
    <PageWrapper className="mx-0">
      <Alert
        {...alert}
        setShow={(show) => {
          setAlert((prev) => ({ ...prev, show }));
        }}
      />

      {enrollmentRespdata.length === 0 && (
        <Tile>
          <div id="shopping-cart-section">
            <div className="mb-6">
              <h1 className="mb-4">
                <Header>Shopping Cart</Header>
              </h1>
              <Paragraph>
                You can register for all or some of the classes in your shopping
                cart. To register, check the box to the left of the course name
                and click the Register for Classes button. To keep a class in
                your cart to consider later, leave the box unchecked. You can
                delete a course by clicking the trash can.
              </Paragraph>
            </div>
            {terms.length > 0 && (
              <div className="mb-10">
                <Paragraph className="mb-2 font-bold">Semester</Paragraph>
                <div data-container="combobox" className="w-[320px]">
                  <Select
                    ariaLabel="Semester"
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    currItem={term}
                    items={terms}
                    onChange={(item) => {
                      setTerm(item);
                    }}
                    className="box-border flex h-[40px] w-full items-center border-[1px] border-solid border-[#ADADAD] pl-2.5 text-[15px] font-medium"
                    icon={
                      <div className="flex h-[38px] w-[40px] cursor-pointer items-center justify-center border-none bg-du-skyblue">
                        <CaretDown className="h-4 w-4" fillColor="white" />
                      </div>
                    }
                  />
                </div>
              </div>
            )}
            <CartList
              impersonate={impersonate}
              idCard={idCard}
              term={term}
              cartItems={cartItems}
              setCartItems={setCartItems}
              fetchShoppingCartDetails={fetchShoppingCartDetails}
              setAlert={setAlert}
            />
            <div className="mt-[30px] flex flex-row justify-center border-0 border-t border-solid border-du-gray pt-[30px] sm:justify-end">
              {cartItems.length > 0 && (
                <button
                  disabled={registerStatus !== FetchStatus.IDLE}
                  className={clsx(
                    "border-none px-5 py-[10px] font-bold",
                    registerStatus === FetchStatus.IDLE
                      ? "bg-du-yellow text-du-black"
                      : "bg-du-dark-gray text-white"
                  )}
                  onClick={() => {
                    handleRegisterForClasses();
                  }}
                >
                  Register for Classes
                </button>
              )}
            </div>
          </div>
        </Tile>
      )}
      {enrollmentRespdata.length > 0 && (
        <RegistrationSummary
          term={term}
          enrollmentRespdata={enrollmentRespdata}
          action={async () => {
            if (term.STRM && idCard?.CAREER) {
              await fetchShoppingCartDetails(term.STRM, idCard.CAREER);
            }
            setEnrollmentRespdata([]);
          }}
        />
      )}
    </PageWrapper>
  );
};
angularize(ShoppingCart, "shoppingCartReact", angular.module("duApp"), {
  impersonate: "<",
});
export default ShoppingCart;
