export enum GradingEnum {
  Graded = "GRD",
  Audid = "AUD",
  PassFail = "PNP",
  Other = "OTHER",
}

export interface GradingModalState {
  currGrading: GradingEnum;
  newGrading: GradingEnum;
  isEditModalOpen: boolean;
  isConfirmModalOpen: boolean;
}
