import CheckboxAlt from "components/CheckboxAlt";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import {
  EField,
  fetchValues,
  IQuery,
} from "pages/newClassSearch/classSearchQuery";
import { careerDisplay, careerOrder } from "pages/newClassSearch/display";
import { resetAllEventChannel } from "pages/newClassSearch/events/resetAllEventMsg";
import { ClassSearchResponse } from "pages/newClassSearch/types";
import React, { useContext, useEffect, useState } from "react";

const careersCampusMap = {
  ADLPH: ["UGRD", "GRAD", "GR4T"],
  EUROP: ["UGRD", "GRAD", "GR4T", "USAL", "GSAL"],
  ASIA: ["UGRD", "GRAD", "GR4T"],
};

const CareerField = () => {
  const {
    state: {
      fields: { campusCode, academicCareerCode },
    },
    classSearchState: { setClassResponseUI, setClassResponseStatus },
    dispatch,
  } = useContext(ClassSearchContext);

  const [careerList, setCareerList] = useState<string[]>([]);

  const fetchCareers = async () => {
    const query: IQuery = {
      select: [EField.academicCareerCode],
      filter: {
        AND: [
          {
            campusCode: {
              equals: campusCode,
            },
          },
        ],
      },
      orderby: {
        academicCareerCode: "asc",
      },
    };

    const classSearchPagination = (await fetchValues(
      query
    )) as ClassSearchResponse;
    const careers = classSearchPagination.value.map(
      (value) => value.academicCareerCode
    );
    setCareerList(careers);
  };

  useEffect(() => {
    const careerList =
      careersCampusMap[campusCode as keyof typeof careersCampusMap];
    if (careerList && careerList.length > 0) {
      setCareerList(careerList);
    }
  }, [campusCode]);

  useEffect(() => {
    dispatch({
      type: "RESET_ALL",
    });
    resetAllEventChannel.emit("onResetAll");
    setClassResponseUI({
      "@odata.context": "",
      "@odata.count": 0,
      "@odata.nextLink": "",
      value: [],
    });
    setClassResponseStatus("pristine");
  }, [academicCareerCode]);

  return (
    <div className="ml-2 mt-5">
      <p className="mb-2 font-bold">Career</p>
      <ul className="space-y-4">
        {careerList
          .sort((a, b) => careerOrder[a] - careerOrder[b])
          .map((career, index) => (
            <li key={index}>
              <div className="flex gap-3">
                <div>
                  <CheckboxAlt
                    id={`cs-career-checkbox-${career}`}
                    value={career === academicCareerCode}
                    onChange={() => {
                      dispatch({
                        type: "UPDATE_ACADEMIC_CAREER_CODE",
                        payload: {
                          academicCareerCode: career,
                        },
                      });
                    }}
                  />
                </div>
                <p className="text-sm font-bold">{careerDisplay[career]}</p>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CareerField;
