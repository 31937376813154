import Header from "components/Header";
import Link from "components/Link";
import OutLink from "components/OutLink";
import PageWrapper from "components/PageWrapper";
import Paragraph from "components/Paragraph";
import Tile from "components/Tile";
import UnorderedList from "components/UnorderedList";
import React, { useEffect } from "react";
import { angularize } from "react-in-angularjs";
import { sendEvents } from "utils/googleTagManager";

const CareerServices = () => {
  useEffect(() => {
    sendEvents();
  }, []);

  return (
    <PageWrapper className="mx-[0] grid grid-cols-1 gap-6">
      <Tile>
        <Header className="mb-4">Virtual Career Events</Header>
        <Paragraph>
          Browse our many recruitment events and career-focused webinars.{" "}
          <OutLink href="https://careerquest.umgc.edu/hub/umgc-careerquest/events?utm_source=StudentCommunications&utm_medium=DUPortal&utm_campaign=PortalCareerQuestEvents">
            Learn more
          </OutLink>
          and register today!
        </Paragraph>
      </Tile>
      <Tile className="space-y-6">
        <Header>Career Services Tools</Header>
        <Paragraph>
          The following tools are available through UMGC's{" "}
          <OutLink href="https://careerquest.umgc.edu/?utm_source=StudentCommunications&utm_medium=DUPortal&utm_campaign=PortalCareerQuest">
            CareerQuest
          </OutLink>
          , your one stop shop for all things career.
        </Paragraph>

        <Paragraph>
          <UnorderedList className="space-y-4">
            <li className="block">
              <OutLink href=" https://careerquest.umgc.edu/?utm_source=StudentCommunications&utm_medium=DUPortal&utm_campaign=PortalVMock">
                VMock
              </OutLink>{" "}
              Improve your resume, optimize your LinkedIn profile, and practice
              interviewing with Vmock's suite of AI-powered tools.
            </li>
            <li className="block">
              <OutLink href="https://careerquest.umgc.edu/?utm_source=StudentCommunications&utm_medium=DUPortal&utm_campaign=PortalCandidCareer">
                Candid Career
              </OutLink>{" "}
              Watch informational interviews for advice from industry
              professionals.
            </li>
            <li className="block">
              <OutLink href="https://careerquest.umgc.edu/?utm_source=StudentCommunications&utm_medium=DUPortal&utm_campaign=PortalInternPLUS">
                InternPLUS
              </OutLink>{" "}
              Find short-term project-based work, virtual job simulations, and
              remote and in-person internships to gain experience.
            </li>
            <li className="block">
              <OutLink href="https://careerquest.umgc.edu/hub/umgc-careerquest/resources/post/steppingblocks?utm_source=StudentCommunications&utm_medium=DUPortal&utm_campaign=PortalSteppingblocks">
                Steppingblocks
              </OutLink>{" "}
              Research careers and get real-world stats about your major,
              interests, and job titles of interest.
            </li>
            <li className="block">
              <OutLink href="https://careerquest.umgc.edu/hub/umgc-careerquest/resources/post/handshake?utm_source=StudentCommunications&utm_medium=DUPortal&utm_campaign=PortalHandshake">
                Handshake
              </OutLink>{" "}
              Optimize your internship or job search and get personalized job
              recommendations based on your major and interests.
            </li>
          </UnorderedList>
        </Paragraph>

        <Paragraph>
          For more information, contact Career Services at{" "}
          <Link href="tel:240-684-2720">240-684-2720</Link> or{" "}
          <OutLink href="mailto:careerservices@umgc.edu">
            careerservices@umgc.edu
          </OutLink>
        </Paragraph>
      </Tile>
    </PageWrapper>
  );
};

angularize(CareerServices, "careerServicesReact", angular.module("duApp"));

export default CareerServices;
