import Header from "components/Header";
import Paragraph from "components/Paragraph";
import Tile from "components/Tile";
import CheckCircle from "icons/CheckCircle";
import ExclamantionTriangle from "icons/ExclamantionTriangle";
import React from "react";
import { EnrollmentRespdatum } from "types/classRegistrationService";

import { TermUI } from "./ShoppingCart";

interface RegistrationSummaryProps {
  term: TermUI;
  enrollmentRespdata: EnrollmentRespdatum[];
  action: () => void;
}

const RegistrationSummary: React.FC<RegistrationSummaryProps> = ({
  term,
  enrollmentRespdata,
  action,
}) => {
  const successResponses = enrollmentRespdata.filter(
    (item) => item.RespStatus === "200"
  );
  const failedResponses = enrollmentRespdata.filter(
    (item) => item.RespStatus === "400"
  );

  return (
    <Tile>
      <div className="mb-6">
        <Header className="mb-4">Registration Summary</Header>
        {failedResponses.map((item, idx) => (
          <div key={idx} className="flex gap-2">
            <ExclamantionTriangle
              className="min-w-[24px] max-w-[24px]"
              fillColor="#C2172D"
            />
            <Paragraph>
              {item.ClassNbr} - {item.RespMsg}
            </Paragraph>
          </div>
        ))}

        {successResponses.length > 0 && (
          <div className="flex gap-2">
            <CheckCircle
              className="min-w-[24px] max-w-[24px]"
              fillColor="#108A27"
            />
            <span>
              You have successfully registered for {successResponses.length}{" "}
              course{successResponses.length === 1 ? "" : "s"}
            </span>
          </div>
        )}

        {successResponses.map((item, idx) => (
          <div key={idx} className="flex gap-2">
            <CheckCircle
              className="min-w-[24px] max-w-[24px]"
              fillColor="#108A27"
            />
            <Paragraph>
              {item.ClassNbr} - {item.RespMsg}
            </Paragraph>
          </div>
        ))}

        <Paragraph className="font-bold">Semester</Paragraph>
        <Paragraph>{term.$name}</Paragraph>
      </div>
      <div className="mt-[30px] flex flex-row justify-end border-0 border-t border-solid border-du-gray pt-[30px]">
        <button
          className="border-none bg-du-yellow px-5 py-[10px] font-bold text-du-black"
          onClick={() => {
            action();
          }}
        >
          Return to Cart
        </button>
      </div>
    </Tile>
  );
};

export default RegistrationSummary;
