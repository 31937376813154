import { DateTime } from "luxon";
import { CartDetail } from "types/classSearchService";
import { UcMyplanCRSPlnr } from "types/degreePlanningDashboardService";

import { IAND, IField, IFilter, IOR } from "./classSearchQuery";
import { MAX_CREDIT_HOURS, MIN_CREDIT_HOURS } from "./contants";
import { Weekday } from "./types";

type UpdateClassFormatCode = {
  type: "UPDATE_CLASS_FORMAT_CODE";
  payload: {
    classFormatCode: string;
  };
};

type UpdateLocationCode = {
  type: "UPDATE_LOCATION_CODE";
  payload: {
    locationCode: string;
  };
};

type UpdateCampusCode = {
  type: "UPDATE_CAMPUS_CODE";
  payload: {
    campusCode: string;
  };
};

type UpdateAcademicCareerCode = {
  type: "UPDATE_ACADEMIC_CAREER_CODE";
  payload: {
    academicCareerCode: string;
  };
};

type UpdateSubjectCode = {
  type: "UPDATE_SUBJECT_CODE";
  payload: {
    subjectCode: string;
  };
};

type UpdateCatalogNumber = {
  type: "UPDATE_CATALOG_NUMBER";
  payload: {
    catalogNumber: string;
  };
};

type UpdateClassFormatCodeList = {
  type: "UPDATE_CLASS_FORMAT_CODE_LIST";
  payload: {
    classFormatCodeList: string[];
  };
};

type UpdateLocationCodeList = {
  type: "UPDATE_LOCATION_CODE_LIST";
  payload: {
    locationCodeList: string[];
  };
};

type UpdateSemesterStartDateList = {
  type: "UPDATE_SEMESTER_START_DATE_LIST";
  payload: {
    semesterStartDateList: string[];
  };
};

type UpdateSessionStartDateList = {
  type: "UPDATE_SESSION_START_DATE_LIST";
  payload: {
    sessionStartDateList: string[];
  };
};

type UpdateStudentProfile = {
  type: "UPDATE_STUDENT_PROFILE";
  payload: {
    emplid: string;
    campusCode: string;
    academicCareerCode: string;
    academicCareerCodeList: string[];
    activeTermCodes: string[];
    impersonate: boolean;
    discountinuedOrAlumni: boolean;
  };
};

type UpdatePlannerList = {
  type: "UPDATE_PLANNER_LIST";
  payload: {
    plannerList: UcMyplanCRSPlnr[];
  };
};

type UpdateCartList = {
  type: "UPDATE_CART_LIST";
  payload: {
    cartList: CartDetail[];
  };
};

type UpdateFreeSearch = {
  type: "UPDATE_FREE_SEARCH";
  payload: {
    freeSearch: string;
  };
};

type UpdateEnrollmentStatus = {
  type: "UPDATE_ENROLLMENT_STATUS";
  payload: {
    enrollmentStatus: "Open" | "Closed";
  };
};

type UpdateDayOfWeek = {
  type: "UPDATE_DAY_OF_WEEK";
  payload: {
    dayOfWeek: Weekday;
    value: boolean;
  };
};

type UpdateCreditHours = {
  type: "UPDATE_CREDIT_HOURS";
  payload: {
    creditHoursMin: number;
    creditHoursMax: number;
  };
};

type UpdateClassNumber = {
  type: "UPDATE_CLASS_NUMBER";
  payload: {
    classNumber: string;
  };
};

type UpdateInstructor = {
  type: "UPDATE_INSTRUCTOR";
  payload: {
    instructor: string;
  };
};

type ResetAll = {
  type: "RESET_ALL";
};

export type ClassSearchAction =
  | UpdateClassFormatCode
  | UpdateLocationCode
  | UpdateCampusCode
  | UpdateAcademicCareerCode
  | UpdateSubjectCode
  | UpdateCatalogNumber
  | UpdateClassFormatCodeList
  | UpdateLocationCodeList
  | UpdateSemesterStartDateList
  | UpdateSessionStartDateList
  | UpdatePlannerList
  | UpdateCartList
  | UpdateStudentProfile
  | UpdateFreeSearch
  | UpdateEnrollmentStatus
  | UpdateDayOfWeek
  | UpdateCreditHours
  | UpdateClassNumber
  | UpdateInstructor
  | ResetAll;

export interface ClassSearchState {
  profile: {
    emplid: string;
    campusCode: string;
    academicCareerCode: string;
    academicCareerCodeList: string[];
    activeTermCodes: string[];
    impersonate: boolean;
    discountinuedOrAlumni: boolean;
  };
  fields: {
    campusCode: string;
    academicCareerCode: string;
    locationCode: string;
    classFormatCode: string;
    subjectCode: string;
    catalogNumber: string;
    classFormatCodeList: string[];
    semesterStartDateList: string[];
    sessionStartDateList: string[];
    locationCodeList: string[];
    enrollmentStatus: string;
    classNumber: string;
    instructor: string;
    creditHoursMin: number;
    creditHoursMax: number;
    classMonday: boolean;
    classTuesday: boolean;
    classWednesday: boolean;
    classThursday: boolean;
    classFriday: boolean;
    classSaturday: boolean;
    classSunday: boolean;
  };
  freeSearch: string;
}

export const classSearchInitialState: ClassSearchState = {
  profile: {
    emplid: "",
    campusCode: "ADLPH",
    academicCareerCode: "",
    academicCareerCodeList: [],
    activeTermCodes: [],
    impersonate: false,
    discountinuedOrAlumni: false,
  },
  fields: {
    // default fields
    campusCode: "",
    academicCareerCode: "",
    // custom fields
    locationCode: "",
    classFormatCode: "",
    subjectCode: "",
    catalogNumber: "",
    classFormatCodeList: [],
    semesterStartDateList: [],
    sessionStartDateList: [],
    locationCodeList: [],
    enrollmentStatus: "Open",
    classNumber: "",
    instructor: "",
    creditHoursMin: MIN_CREDIT_HOURS,
    creditHoursMax: MAX_CREDIT_HOURS,
    classMonday: false,
    classTuesday: false,
    classWednesday: false,
    classThursday: false,
    classFriday: false,
    classSaturday: false,
    classSunday: false,
  },
  freeSearch: "",
};

const weekdayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const getFieldList = (
  fields: typeof classSearchInitialState.fields,
  academicCareerCodeList: string[]
) => {
  const fieldsCloned = { ...fields } as Partial<typeof fields>;

  if (
    fieldsCloned.creditHoursMin === MIN_CREDIT_HOURS &&
    fieldsCloned.creditHoursMax === MAX_CREDIT_HOURS
  ) {
    delete fieldsCloned.creditHoursMin;
    delete fieldsCloned.creditHoursMax;
  }

  const fieldEntries = Object.entries(fieldsCloned);
  const fieldList: (IField | IOR)[] = [];

  const fieldItemWeekdays: IOR = {
    OR: [],
  };

  for (let i = 0; i < fieldEntries.length; i++) {
    const [fieldName, fieldValue] = fieldEntries[i];
    let fieldItem: IField | IOR;

    if (fieldValue.constructor === Array) {
      if (fieldValue.length === 0) continue;
      fieldItem = {
        [fieldName]: {
          in: fieldValue,
        },
      } as unknown as IField;
    } else if (fieldName.endsWith("Min")) {
      fieldItem = {
        [fieldName.replace("Min", "")]: {
          gte: fieldValue,
        },
      } as unknown as IField;
    } else if (fieldName.endsWith("Max")) {
      fieldItem = {
        [fieldName.replace("Max", "")]: {
          lte: fieldValue,
        },
      } as unknown as IField;
    } else if (
      weekdayNames.some((dayName) => fieldName === `class${dayName}`)
    ) {
      if (!fieldValue) continue;
      fieldItemWeekdays.OR.push({
        [fieldName]: {
          equals: fieldValue,
        },
      } as unknown as IField);
      continue;
    } else if (fieldName === "enrollmentStatus") {
      if (fieldValue === "Closed") {
        fieldItem = {
          OR: [
            { [fieldName]: { equals: "Open" } },
            { [fieldName]: { equals: "Closed" } },
          ],
        };
      } else {
        fieldItem = {
          [fieldName]: {
            equals: "Open",
          },
        };
      }
    } else if (fieldName === "academicCareerCode") {
      if (academicCareerCodeList.length > 1 && fieldValue === "UGRD") {
        fieldItem = {
          OR: [
            {
              [fieldName]: {
                equals: "UGRD",
              },
            },
            {
              [fieldName]: {
                equals: "USAL",
              },
            },
          ],
        };
      } else {
        fieldItem = {
          [fieldName]: {
            equals: fieldValue,
          },
        } as unknown as IField;
      }
    } else {
      if (fieldValue === "") continue;

      fieldItem = {
        [fieldName]: {
          equals: fieldValue,
        },
      } as unknown as IField;
    }

    fieldList.push(fieldItem);
  }

  if (fieldItemWeekdays.OR.length > 0) {
    fieldList.push(fieldItemWeekdays);
  }

  return fieldList;
};

export const getFilter = (
  fields: typeof classSearchInitialState.fields,
  fieldList: (IField | IOR | IAND)[]
) => {
  let filter: IFilter;

  fieldList.push({
    classStartDate: {
      gte: DateTime.now().toFormat("yyyy-MM-dd"),
    },
  });

  fieldList.push({
    schedulePrint: {
      equals: "Y",
    },
  });

  if (
    fields.campusCode !== "ADLPH" &&
    (fields.classFormatCodeList.some((format) => format === "WT") ||
      fields.classFormatCodeList.length === 0)
  ) {
    let clonedFieldList = JSON.parse(JSON.stringify(fieldList)) as (
      | IField
      | IAND
      | IOR
    )[];

    clonedFieldList = clonedFieldList.map((field) => {
      if ("campusCode" in field) {
        return {
          campusCode: {
            equals: "ADLPH",
          },
        };
      }
      return field;
    }) as typeof clonedFieldList;

    clonedFieldList = clonedFieldList.filter((field) => {
      const [key, value] = Object.entries(field)[0];
      if (key === "OR") {
        for (let i = 0; i < value.length; i++) {
          const item = value[i];
          const [subKey] = Object.entries(item)[0];
          if (subKey === "locationCode" || subKey === "classFormatCode") {
            return false;
          }
        }
      }
      return true;
    });

    // if campus is not ADLPH and location list has WEB, add Online classes to the search
    // if (
    //   fields.locationCodeList.some((loc) => loc === "WEB") ||
    //   fields.locationCodeList.length === 0
    // ) {
    //   clonedFieldList.push({
    //     locationCode: {
    //       equals: "WEB",
    //     },
    //   });
    // }

    // if campus is not ADLPH and class format list has WT, add Online classes to the search
    if (
      fields.classFormatCodeList.some((format) => format === "WT") ||
      fields.classFormatCodeList.length === 0
    ) {
      clonedFieldList.push({
        classFormatCode: {
          equals: "WT",
        },
      });
    }

    filter = {
      OR: [
        { AND: fieldList },
        {
          AND: clonedFieldList,
        },
      ],
    };
  } else {
    filter = {
      AND: fieldList,
    };
  }

  return filter;
};

export const classSearchReducer = (
  state: ClassSearchState,
  action: ClassSearchAction
) => {
  const { type } = action;

  switch (type) {
    case "UPDATE_CAMPUS_CODE": {
      const { campusCode } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          campusCode,
        },
      };
    }
    case "UPDATE_ACADEMIC_CAREER_CODE": {
      const { academicCareerCode } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          academicCareerCode,
        },
      };
    }
    case "UPDATE_CLASS_FORMAT_CODE": {
      const { classFormatCode } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          classFormatCode,
        },
      };
    }
    case "UPDATE_SUBJECT_CODE": {
      const { subjectCode } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          subjectCode,
        },
      };
    }
    case "UPDATE_CATALOG_NUMBER": {
      const { catalogNumber } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          catalogNumber,
        },
      };
    }
    case "UPDATE_LOCATION_CODE": {
      const { locationCode } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          locationCode,
        },
      };
    }
    case "UPDATE_CLASS_FORMAT_CODE_LIST": {
      const { classFormatCodeList } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          classFormatCodeList,
        },
      };
    }
    case "UPDATE_LOCATION_CODE_LIST": {
      let { locationCodeList } = action.payload;

      if (state.fields.classFormatCodeList.includes("WT")) {
        locationCodeList.push("WEB");
      } else {
        locationCodeList = locationCodeList.filter((item) => item !== "WEB");
      }

      return {
        ...state,
        fields: {
          ...state.fields,
          locationCodeList,
        },
      };
    }
    case "UPDATE_SEMESTER_START_DATE_LIST": {
      const { semesterStartDateList } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          semesterStartDateList,
        },
      };
    }
    case "UPDATE_SESSION_START_DATE_LIST": {
      const { sessionStartDateList } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          sessionStartDateList,
        },
      };
    }
    case "UPDATE_PLANNER_LIST": {
      const { plannerList } = action.payload;

      return {
        ...state,
        plannerList,
      };
    }
    case "UPDATE_CART_LIST": {
      const { cartList } = action.payload;

      return {
        ...state,
        cartList,
      };
    }
    case "UPDATE_STUDENT_PROFILE": {
      const {
        emplid,
        campusCode,
        academicCareerCode,
        academicCareerCodeList,
        activeTermCodes,
        impersonate,
        discountinuedOrAlumni,
      } = action.payload;

      return {
        ...state,
        profile: {
          emplid,
          campusCode,
          academicCareerCode,
          academicCareerCodeList,
          activeTermCodes,
          impersonate,
          discountinuedOrAlumni,
        },
      };
    }
    case "UPDATE_FREE_SEARCH": {
      const { freeSearch } = action.payload;

      return {
        ...state,
        freeSearch,
      };
    }

    case "UPDATE_ENROLLMENT_STATUS": {
      const { enrollmentStatus } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          enrollmentStatus,
        },
      };
    }
    case "UPDATE_DAY_OF_WEEK": {
      const { dayOfWeek, value } = action.payload;

      const capitalizeFirstLetter = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
      };

      return {
        ...state,
        fields: {
          ...state.fields,
          [`class${capitalizeFirstLetter(dayOfWeek)}`]: value,
        },
      };
    }
    case "UPDATE_CREDIT_HOURS": {
      const { creditHoursMin, creditHoursMax } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          creditHoursMin,
          creditHoursMax,
        },
      };
    }
    case "UPDATE_CLASS_NUMBER": {
      const { classNumber } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          classNumber,
        },
      };
    }
    case "UPDATE_INSTRUCTOR": {
      const { instructor } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          instructor,
        },
      };
    }
    case "RESET_ALL": {
      return {
        ...state,
        fields: {
          ...classSearchInitialState.fields,
          campusCode: state.fields.campusCode,
          academicCareerCode: state.fields.academicCareerCode,
        },
        freeSearch: "",
      };
    }
    default:
      return state;
  }
};
