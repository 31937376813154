import "./style.css";

import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import CaretDown from "icons/CaretDown";
import CaretRight from "icons/CaretRight";
import TrashAlt from "icons/TrashAlt";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { AddressResponse } from "types/profileAddressService";
import { DemogResponse } from "types/profileDemographicInfoService";
import { NameResponse } from "types/profileNameService";
interface Field {
  name: string;
  value: string;
}

interface EffectiveDate {
  id: number;
  isVisible: boolean;
  date: string;
  originalItem: any;
  fields: Field[];
}

type EffectiveDateTypes =
  | "gender-pronouns"
  | "diploma"
  | "all-address"
  | "Diploma-name"
  | "Preferred-name"
  | "Legal-name"
  | "Former-name"
  | "Permanent-address"
  | "Mailing-address"
  | "Diploma-address";

const genderIdentityValues = [
  {
    uri: "/v1/genderIdentity/FE",
    code: "FE",
    name: "Female",
    desc: "Female",
  },
  {
    uri: "/v1/genderIdentity/MA",
    code: "MA",
    name: "Male",
    desc: "Male",
  },
  {
    uri: "/v1/genderIdentity/NB",
    code: "NB",
    name: "Non-Binary",
    desc: "Non-Binary",
  },
  {
    uri: "/v1/genderIdentity/OP",
    code: "OP",
    name: "Opt-Out",
    desc: "Opt-Out",
  },
];

const preferredPronounsValues = [
  {
    uri: "/v1/preferredPronouns/HE",
    code: "HE",
    name: "He/Him/His",
    desc: "He",
  },
  {
    uri: "/v1/preferredPronouns/SH",
    code: "SH",
    name: "She/Her/Hers",
    desc: "She",
  },
  {
    uri: "/v1/preferredPronouns/TH",
    code: "TH",
    name: "They/Them/Theirs",
    desc: "They",
  },
  {
    uri: "/v1/preferredPronouns/ZE",
    code: "ZE",
    name: "Ze/Hir/Hirs",
    desc: "Ze",
  },
  {
    uri: "/v1/preferredPronouns/ZR",
    code: "ZR",
    name: "Ze/Zir/Zirs",
    desc: "Zr",
  },
];

interface EffectiveDateProps {
  input?: EffectiveDateTypes;
}

const EffectiveDate: React.FC<EffectiveDateProps> = ({ input }) => {
  const [effectiveDates, setEffectiveDates] = useState<EffectiveDate[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [globalError, setGlobalError] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [eDate, setEDate] = useState<EffectiveDate>();
  const [isOpen, setIsOpen] = useState(false);

  const fetchEfectiveDateData = async (input?: EffectiveDateTypes) => {
    if (!input) return;
    let effectiveDates: EffectiveDate[] = [];
    switch (input) {
      case "gender-pronouns": {
        const demogResponse = await (getDuService({
          service: "profileDemographicInfoService",
          method: "getProfileDemographicInfo",
        }) as Promise<DemogResponse>);
        const ucDuDemogPrsnl = demogResponse?.DEMOG_RESP?.UC_DU_DEMOG_PRSNL;
        for (let i = 0; i < ucDuDemogPrsnl.length; i++) {
          const item = ucDuDemogPrsnl[i];
          const genderIdentityDesc = genderIdentityValues.find(
            (val) => val.code === item.SCC_GENDER_ID
          );
          const preferredPronounsDesc = preferredPronounsValues.find(
            (val) => val.code === item.SCC_PRONOUNS
          );
          effectiveDates.push({
            id: i + 1,
            date: item.EFFDT,
            isVisible: false,
            originalItem: item,
            fields: [
              {
                name: "Gender Identity",
                value: (genderIdentityDesc && genderIdentityDesc.name) || "",
              },
              {
                name: "Pronouns",
                value:
                  (preferredPronounsDesc && preferredPronounsDesc.name) || "",
              },
            ],
          });
        }
        break;
      }
      case "Diploma-name":
      case "Preferred-name":
      case "Legal-name":
      case "Former-name": {
        const mapping = {
          "Diploma-name": "DEG",
          "Preferred-name": "PRF",
          "Legal-name": "PRI",
          "Former-name": "FMR",
        };

        const nameResponse = await (getDuService({
          service: "profileNameService",
          method: "getProfileName",
        }) as Promise<NameResponse>);

        const duNameType = nameResponse.Name_Resp.UC_DU_NAME_TYPE.find(
          (item) => item.NAME_TYPE === mapping[input]
        );

        const duNameDetails = duNameType?.UC_DU_NAME_DETAILS;
        if (!duNameDetails) {
          effectiveDates = [];
          setEffectiveDates(effectiveDates);
          return;
        }
        for (let i = 0; i < duNameDetails.length; i++) {
          const item = duNameDetails[i];
          effectiveDates.push({
            id: i + 1,
            date: item.EFFDT,
            isVisible: false,
            originalItem: item,
            fields: [
              {
                name: "Prefix",
                value: item.NAME_PREFIX,
              },
              {
                name: "First Name",
                value: item.FIRST_NAME,
              },
              {
                name: "Middle Name",
                value: item.MIDDLE_NAME,
              },
              {
                name: "Suffix Name",
                value: item.NAME_SUFFIX,
              },
            ],
          });
        }
        break;
      }
      case "Permanent-address":
      case "Mailing-address":
      case "Diploma-address": {
        const mapping = {
          "Permanent-address": "PERM",
          "Mailing-address": "MAIL",
          "Diploma-address": "DIPL",
        };
        effectiveDates = [];
        const addressResponse = await (getDuService({
          service: "profileAddressService",
          method: "getProfileAddress",
        }) as Promise<AddressResponse>);

        const duAddressType = addressResponse.Address_Resp.UC_DU_ADDR_TYPE.find(
          (item) => item.ADDRESS_TYPE === mapping[input]
        );

        if (!duAddressType) {
          effectiveDates = [];
          setEffectiveDates(effectiveDates);
          return;
        } else {
          const duAddrDetails = duAddressType.UC_DU_ADDR_DETAILS;
          for (let i = 0; i < duAddrDetails.length; i++) {
            const item = duAddrDetails[i];
            effectiveDates.push({
              id: i + 1,
              date: item.EFFDT,
              isVisible: false,
              originalItem: item,
              fields: [
                {
                  name: "Country",
                  value: item.COUNTRY,
                },
                {
                  name: "Address Line 1",
                  value: item.ADDRESS1,
                },
                {
                  name: "City",
                  value: item.CITY,
                },
                {
                  name: "State",
                  value: item.STATE,
                },
                {
                  name: "Zip",
                  value: item.POSTAL,
                },
              ],
            });
          }
        }
        break;
      }
    }
    setEffectiveDates(effectiveDates);
  };

  const handleShowDialog = (isShow: boolean) => {
    if (isShow) {
      setIsOpen(false);
      eDate && handleDeleteEffectiveDate(eDate);
    } else {
      setShowDialog(false);
      const bodyELe: any = document.querySelector(".md-dialog-container");
      if (bodyELe && bodyELe.style) {
        bodyELe.style["top"] = 0;
      }
      setIsOpen(false);
    }
  };

  const clickOpen = () => {
    document.body.style.top = "0 !important";
    document.body.style.overflow = "visible !important";
  };

  const handleDeleteEffectiveDate = async (effectiveDate: EffectiveDate) => {
    if (!input) return;
    let deleteResult;
    setIsDeleting(true);
    setGlobalError("");
    try {
      if (input === "gender-pronouns") {
        const edDelete = {
          UC_DU_UPDT_PRSNL_REQ: {
            EMPLID: sessionStorage.getItem("userID"),
            UC_DU_UPD_PRSNl_DTLS: [
              {
                ...effectiveDate.originalItem,
                DELETE_FLAG: "Y",
              },
            ],
          },
        };
        await (getDuService(
          {
            service: "profileDemographicInfoService",
            method: "setProfileDemographicInfo",
          },
          edDelete
        ) as Promise<any>);
      } else if (/\w+-name/i.test(input as string)) {
        const mapping = {
          "Diploma-name": "DEG",
          "Preferred-name": "PRF",
          "Legal-name": "PRI",
          "Former-name": "FMR",
        };
        const edDelete = {
          UC_DU_UPDT_NAME_REQ: {
            EMPLID: sessionStorage.getItem("userID"),
            UC_DU_UPDT_NAME_TYPE: [
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore:
                NAME_TYPE: mapping[input],
                COUNTRY_NM_FORMAT: effectiveDate.originalItem.COUNTRY_NM_FORMAT,
                NAME_PREFIX: effectiveDate.originalItem.NAME_PREFIX,
                FIRST_NAME: effectiveDate.originalItem.FIRST_NAME,
                MIDDLE_NAME: effectiveDate.originalItem.MIDDLE_NAME,
                LAST_NAME: effectiveDate.originalItem.LAST_NAME,
                NAME_SUFFIX: effectiveDate.originalItem.NAME_SUFFIX,
                EFFDT: effectiveDate.originalItem.EFFDT,
                DELETE_FLAG: "Y",
              },
            ],
          },
        };
        await (getDuService(
          {
            service: "profileNameService",
            method: "setProfileInfo",
          },
          edDelete
        ) as Promise<any>);
      } else if (/\w+-address/i.test(input as string)) {
        const mapping = {
          "Permanent-address": "PERM",
          "Mailing-address": "MAIL",
          "Diploma-address": "DIPL",
        };
        const edDelete = {
          UC_DU_UPDT_ADDR_REQ: {
            EMPLID: sessionStorage.getItem("userID"),
            UC_DU_UPDT_ADDR_TYPE: [
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore:
                ADDRESS_TYPE: mapping[input],
                DELETE_FLAG: "Y",
                UC_DU_UPDT_ADDR_DETAILS: {
                  ...effectiveDate.originalItem,
                },
              },
            ],
          },
        };
        await (getDuService(
          {
            service: "profileAddressService",
            method: "setProfileAddress",
          },
          edDelete
        ) as Promise<any>);
      }
      setShowDialog(false);
      const customEvent = new CustomEvent("handleDelete", {
        detail: effectiveDates.length,
      });
      window.dispatchEvent(customEvent);
      fetchEfectiveDateData(input);
    } catch (error) {
      setGlobalError(
        "We are unable to complete your request at this time. We apologize for any inconvenience and are working to correct the issue. Please try again later."
      );
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    fetchEfectiveDateData(input);
    const bodyELe = document.body;
    if (isOpen) {
      const bodyELe = document.body;
      bodyELe.style.top = "0";

      const containerELe: any = document.querySelector(".md-dialog-container");
      if (containerELe && containerELe.style) {
        containerELe.style["top"] = 0;
      }
    }
  }, [input, isOpen]);

  return (
    <div className="space-y-1">
      {effectiveDates
        .sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA.getTime() - dateB.getTime();
        })
        .filter((ed) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const [, year, month, day] = /(\d{4})-(\d{2})-(\d{2})/.exec(ed.date)!;
          const monthIndex = Number(month) - 1;
          const edDate = new Date(
            Number(year),
            monthIndex,
            Number(day),
            0,
            0,
            0
          );
          return edDate.getTime() > today.getTime();
        })
        .slice(0, 3)
        .map((ed) => (
          <div className="flex gap-2" key={ed.id}>
            <div>
              <button
                className="mt-1 border-none"
                onClick={() => {
                  setEffectiveDates((prev) => {
                    const newEffectiveDates = prev.map((edItem) => {
                      if (edItem.id === ed.id) {
                        edItem.isVisible = !edItem.isVisible;
                      }
                      return edItem;
                    });
                    return newEffectiveDates;
                  });
                }}
              >
                <CaretDown
                  fillColor="#003e6a"
                  className={clsx("h-4 w-4", !ed.isVisible && "hidden")}
                />
                <CaretRight
                  fillColor="#003e6a"
                  className={clsx("h-4 w-4", ed.isVisible && "hidden")}
                />
              </button>
            </div>
            <div>
              <div className="flex gap-2">
                <Paragraph className="font-bold">
                  You scheduled an update for{" "}
                  {DateTime.fromISO(ed.date).toFormat("MM/dd/yyyy")}
                </Paragraph>
                <button
                  className="border-none"
                  onClick={() => {
                    setEDate(ed);
                    setShowDialog(true);
                    setIsOpen(true);
                    clickOpen();
                  }}
                  disabled={isDeleting}
                >
                  <TrashAlt fillColor="#003e6a" className="w-4" />
                </button>
              </div>
              <ul className={clsx("space-y-1", !ed.isVisible && "hidden")}>
                {ed.fields.map((f, idx) => (
                  <li
                    key={idx}
                    className={clsx("text-[15px]", f.value === "" && "hidden")}
                  >
                    {f?.name}: {f.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      {globalError !== "" && (
        <p className="text-sm text-du-red">{globalError}</p>
      )}

      {showDialog && (
        <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
          <ModalWrapper show={isOpen} className="d-container">
            <div className="flex justify-end">
              <Dialog.Close
                className="border-none text-3xl"
                aria-label="Close"
                onClick={() => handleShowDialog(false)}
              >
                &times;
              </Dialog.Close>
            </div>
            <Paragraph className="text15 text-center text-base">
              Are you sure you want to delete ?
            </Paragraph>
            <div className="mt-[30px] flex flex-row justify-center gap-4 border-0 border-t border-solid border-du-gray pt-[30px]">
              <button
                className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
                onClick={() => handleShowDialog(true)}
              >
                Yes
              </button>
              <Dialog.Close
                onClick={() => handleShowDialog(false)}
                className="border border-solid border-du-yellow px-4 py-2 font-bold text-du-black"
              >
                Cancel
              </Dialog.Close>
            </div>
          </ModalWrapper>
        </Dialog.Root>
      )}
    </div>
  );
};

angularize(EffectiveDate, "effectiveDateReact", angular.module("duApp"), {
  input: "<",
});
export default EffectiveDate;
