import React from "react";

const ClassTableSkeleton = () => {
  return (
    <div>
      <div className="mb-2 mt-6">
        <div className="h-4 w-[220px] animate-pulse rounded-full bg-du-gray"></div>
      </div>
      <ul className="space-y-3">
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
        <li className="h-[97px] rounded-md border border-du-dark-gray bg-white p-4">
          <div className="flex items-center gap-2">
            <div className="w-[65px]">
              <div className="flex h-[63px] w-[63px] flex-col items-center justify-center gap-2 rounded-lg border border-solid border-du-dark-gray p-2">
                <div className="h-3.5 w-[40px] animate-pulse rounded-full bg-du-gray"></div>
                <div className="h-3.5 w-[30px] animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[80%] grow">
              <div className="mb-2 h-3.5 w-[25%] animate-pulse rounded-full bg-du-gray"></div>
              <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
            </div>
            <div className="flex w-[20%] justify-end">
              <div className="flex h-[30px] w-[83.75px] items-center justify-center rounded-full border border-solid border-du-gray bg-white px-2 py-1">
                <div className="inline-block h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div className="w-[20%]">
              <div className="inline-flex h-[36.5px] w-[160px] items-center rounded-md border border-solid border-du-gray px-3 py-2 text-center font-bold">
                <div className="h-3.5 w-full animate-pulse rounded-full bg-du-gray"></div>
              </div>
            </div>
            <div></div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ClassTableSkeleton;
