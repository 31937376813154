import * as SliderPrimitive from "@radix-ui/react-slider";
import React, { useState } from "react";
import { angularize } from "react-in-angularjs";

interface CreditHoursProps {
  minUnits?: number;
  maxUnits?: number;
}

const CreditHours: React.FC<CreditHoursProps> = ({ minUnits, maxUnits }) => {
  const DEFAULT_MIN = 1;
  const DEFAULT_MAX = 6;
  const [minCredits, setMinCredits] = useState(minUnits ?? DEFAULT_MIN);
  const [maxCredits, setMaxCredits] = useState(maxUnits ?? DEFAULT_MAX);

  const getBoldText = (value: number) => {
    if (minCredits === value || maxCredits === value) {
      return "font-bold";
    }
    return "";
  };

  return (
    <div>
      <label
        htmlFor="cs-credit-hours"
        className="mb-2 block font-bold text-du-black"
      >
        Credit Hours
      </label>
      <div className="mb-1.5 box-border flex w-64 flex-row justify-between px-1.5 text-du-black">
        <span className={getBoldText(1)}>1</span>
        <span className={getBoldText(2)}>2</span>
        <span className={getBoldText(3)}>3</span>
        <span className={getBoldText(4)}>4</span>
        <span className={getBoldText(5)}>5</span>
        <span className={getBoldText(6)}>6</span>
      </div>
      <SliderPrimitive.Root
        name="cs-credit-hours"
        id="cs-credit-hours"
        onValueChange={(valueRange) => {
          setMinCredits(valueRange[0]);
          setMaxCredits(valueRange[1]);
        }}
        defaultValue={[minCredits, maxCredits]}
        min={1}
        max={6}
        step={1}
        aria-label="Credit Hours"
        className="relative flex h-5 w-64 touch-none items-center"
      >
        <SliderPrimitive.Track className="relative h-2 w-full grow rounded-full border border-solid border-[#ADADAD] bg-white">
          <SliderPrimitive.Range className="absolute h-full rounded-full bg-du-skyblue" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          className={
            "box-border block h-6 w-6 rounded-full border-4 border-solid border-du-skyblue bg-white focus-visible:border-du-black focus-visible:outline-none"
          }
        />
        <SliderPrimitive.Thumb
          className={
            "box-border block h-6 w-6 rounded-full border-4 border-solid border-du-skyblue bg-white focus-visible:border-du-black focus-visible:outline-none"
          }
        />
      </SliderPrimitive.Root>
      <span className="hidden" id="react-class-search-min-credits">
        {minCredits}
      </span>
      <span className="hidden" id="react-class-search-max-credits">
        {maxCredits}
      </span>
    </div>
  );
};

angularize(CreditHours, "creditHoursReact", angular.module("duApp"), {
  minUnits: "<",
  maxUnits: "<",
});

export default CreditHours;
