import React from "react";

interface CheckProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const Check: React.FC<CheckProps> = ({ fillColor, ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="-10 0 466 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M413.505 91.9512l-280.015 280.015l-98.9951 -98.9951c-4.68652 -4.68555 -12.2842 -4.68555 -16.9707 0l-11.3135 11.3135c-4.68555 4.68555 -4.68555 12.2842 0 16.9707l118.794 118.794c4.68652 4.68652 12.2842 4.68652 16.9707 0l299.813 -299.812
c4.68555 -4.68652 4.68555 -12.2842 0 -16.9717l-11.3145 -11.3135c-4.68555 -4.68652 -12.2832 -4.68652 -16.9697 0z"
        fill={fillColor ? fillColor : "#ffffff"}
      />
    </svg>
  );
};

export default Check;
