import clsx from "clsx";
import React from "react";

interface HighlightedProps {
  text: string;
  highlight: string;
  className?: string;
}

const Highlighted: React.FC<HighlightedProps> = ({
  text,
  highlight,
  className,
}) => {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  if (!highlight || !parts)
    return <span className={clsx(className)}>{text}</span>;
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          className={clsx(
            part.toLowerCase() === highlight.toLowerCase() &&
              "rounded-sm bg-[#fbe59d]",
            className
          )}
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
};

export default Highlighted;
