export const loadingStateUpdate = (isLoading: boolean) => {
  const spinnerElement = document.querySelector("#du-main-spinner");
  const classSearchButton = document.querySelector(
    "#class-search-submit-button"
  );
  if (!spinnerElement || !classSearchButton) return;
  if (isLoading) {
    spinnerElement.classList.add("du-show");
    classSearchButton.setAttribute("disabled", "");
  } else {
    spinnerElement.classList.remove("du-show");
    classSearchButton.removeAttribute("disabled");
  }
};
