import React from "react";

interface HeaderProps {
  children?: React.ReactNode;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ children, className }) => {
  return (
    <p className={`block font-secondary text-4xl font-thin ${className ?? ""}`}>
      {children}
    </p>
  );
};

export default Header;
