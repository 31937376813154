import React from "react";

interface AngleUpProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const AngleUp: React.FC<AngleUpProps> = ({ fillColor, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" {...props}>
      <path
        d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"
        fill={fillColor ? fillColor : "#000000"}
      />
    </svg>
  );
};

export default AngleUp;
