/* eslint-disable @typescript-eslint/no-explicit-any */
import "./miltarystyles.css";

// import { lighten } from "@mui/system";
import CaretDown from "icons/CaretDown";
import React, { useEffect, useRef, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { countryListResponse } from "types/countryListService";

interface Option {
  value: string;
  label: string;
}


const DropDownList: React.FC = () => {
  // const { DropDownRef, DropDownRef1 } = props;
  const [selectedOption, setselectedOption] = useState<Option | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState<Option[]>([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [selectOpen, setSelectOpen] = useState(false);
  const [idBase, setIdBase] = useState("divCntry");
  const [ariaAcc, setAriaAcc] = useState("combo");
  const DropDownRef1 = useRef<HTMLDivElement>(null);

  const DropDownRef = useRef<HTMLDivElement>(null);

  const handleOptionSelect = (option: Option, index: number) => {
    setselectedOption(option);
    setIsOpen(false);
    onOptionClick(index);
    const cntryResidence = new CustomEvent("countryOfResidenceValue", {
      detail: option,
    });
    window.dispatchEvent(cntryResidence);
  };

  const handleOptionSelectKey = (
    option: Option,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === "Enter") {
      setselectedOption(option);
      setIsOpen(false);
      setSelectOpen(false);
      const cntryResidence = new CustomEvent("countryOfResidenceValue", {
        detail: option,
      });
      window.dispatchEvent(cntryResidence);
    } else if (event.key === "Escape") {
      toggleDropDown();
    }
  };

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    onComboKeyDown(event);
  };

  const SelectActions = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    Previous: 6,
    Select: 7,
    Type: 8,
    Click: 9,
  };

  function getActionFromKey(
    event: React.KeyboardEvent<HTMLDivElement>,
    menuOpen: boolean
  ) {
    const { key, altKey, ctrlKey, metaKey } = event;
    const openKeys = ["ArrowDown", "ArrowUp", "Enter", " "];
    if (!menuOpen && openKeys.includes(key)) {
      return SelectActions.Open;
    }
    if (key === "Home") {
      return SelectActions.First;
    }
    if (key === "End") {
      return SelectActions.Last;
    }
    if (
      key === "Backspace" ||
      key === "Clear" ||
      (key.length === 1 && key !== " " && !altKey && !ctrlKey && !metaKey)
    ) {
      return SelectActions.Type;
    }
    if (menuOpen) {
      if (key === "ArrowUp" && altKey) {
        return SelectActions.CloseSelect;
      } else if (key === "ArrowDown" && !altKey) {
        return SelectActions.Next;
      } else if (key === "ArrowUp") {
        return SelectActions.Previous;
      } else if (key === "Escape" || key === "Tab") {
        return SelectActions.Close;
      } else if (key === "Enter" || key === " ") {
        return SelectActions.CloseSelect;
      }
    }
  }
  function getUpdatedIndex(
    currentIndex: number,
    maxIndex: number,
    action: number
  ) {
    switch (action) {
      case SelectActions.First:
        return 0;
      case SelectActions.Last:
        return maxIndex;
      case SelectActions.Previous:
        return Math.max(0, currentIndex - 1);
      case SelectActions.Next:
        return Math.min(maxIndex, currentIndex + 1);
      default:
        return currentIndex;
    }
  }

  function isElementInView(element: any) {
    const bounding = element.getBoundingClientRect();

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  function isScrollable(element: any) {
    return element && element.clientHeight < element.scrollHeight;
  }

  function maintainScrollVisibility(activeElement: any, scrollParent: any) {
    const { offsetHeight, offsetTop } = activeElement;
    const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent;

    const isAbove = offsetTop < scrollTop;
    const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

    if (isAbove) {
      scrollParent.scrollTo(0, offsetTop);
    } else if (isBelow) {
      scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
    }
  }

  const onComboClick = () => {
    updateMenuState(!selectOpen, false);
  };

  const onComboKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const max = option.length - 1;
    const action = getActionFromKey(event, selectOpen);

    switch (action) {
      case SelectActions.Last:
      case SelectActions.First:
        updateMenuState(true);
      // intentional fallthrough
      case SelectActions.Next:
      case SelectActions.Previous:
        event.preventDefault();
        return onOptionChange(getUpdatedIndex(activeIndex, max, action));
      case SelectActions.CloseSelect:
        event.preventDefault();
        selectOption(activeIndex);
      // intentional fallthrough
      case SelectActions.Close:
        event.preventDefault();
        return updateMenuState(false);
      case SelectActions.Type:
        return onComboType();
      case SelectActions.Open:
        event.preventDefault();
        return updateMenuState(true);
      default:
        toggleDropDown();
    }
  };

  const onComboType = () => {
    updateMenuState(true);
  };

  const onOptionChange = (index: number) => {
    setActiveIndex(index);
    console.log(setIdBase);
    setAriaAcc(`${idBase}-${index}`);

    const options = document.querySelectorAll("[role=option]");
    const listboxEl = document.querySelectorAll("[role=listbox]");
    [...options].forEach((optionEl) => {
      optionEl?.classList.remove("option-current");
    });
    options[index]?.classList.add("option-current");

    if (isScrollable(listboxEl)) {
      maintainScrollVisibility(options[index], listboxEl);
    }

    if (!isElementInView(options[index])) {
      options[index]?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  const onOptionClick = (index: number) => {
    onOptionChange(index);
    selectOption(index);
    updateMenuState(false);
  };

  const selectOption = (index: number) => {
    setActiveIndex(index);

    setselectedOption(option[index]);

    const options = document.querySelectorAll("[role=option]");
    [...options].forEach((optionEl) => {
      optionEl.setAttribute("aria-selected", "false");
    });
    options[index].setAttribute("aria-selected", "true");
  };

  const updateMenuState = (open: boolean, callFocus = false) => {
    if (selectOpen === open) {
      return;
    }

    setSelectOpen(open);
    console.log("updatemenu", open);
    const comboEl: any = document.querySelector("[role=combobox]");
    const el: any = document.querySelector("[role=combobox]");
    comboEl?.setAttribute("aria-expanded", `${open}`);
    open ? el.classList.add("open") : el.classList.remove("open");

    const activeID = open ? `${idBase}-${activeIndex}` : "";
    comboEl?.setAttribute("aria-activedescendant", activeID);

    if (activeID === "" && !isElementInView(comboEl)) {
      comboEl?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

    callFocus && comboEl?.focus();
  };

  const getLocations = async () => {
    try {
      const locationData = (await getDuService({
        service: "countryListService",
        method: "getCountryList",
      })) as countryListResponse;

      const mappedOptions: Option[] = locationData.countryValues.map(
        (item) => ({
          label: item.Name,
          value: item.Code,
        })
      );

      setOption(mappedOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
    // if (selectOpen && DropDownRef.current) {
    //   DropDownRef.current.focus();
    // } else {
    DropDownRef1.current?.focus();
    // }
  }, [selectOpen]);

  return (
    <div className="combo js-select">
      <div className="flex space-x-0">
        <div
          id="divCntry"
          ref={DropDownRef1}
          onClick={() => setSelectOpen(!selectOpen)}
          onKeyDown={handleKeyDown}
          role="combobox"
          tabIndex={0}
          aria-describedby="errorMessage"
          aria-controls="divCntry1"
          aria-haspopup="listbox"
          aria-expanded={selectOpen}
          aria-labelledby="lblCr"
          className="combo-input relative box-border h-[40px] w-full items-center border-[1px] border-solid border-[#ADADAD] p-[10px] pl-2.5 text-[15px] font-medium"
          aria-autocomplete="none"
          aria-activedescendant={ariaAcc || undefined}
        >
          {selectedOption ? selectedOption.label : ""}
          <div
            onClick={() => setSelectOpen(!selectOpen)}
            onKeyDown={handleKeyDown}
            className="absolute right-0 top-0 flex h-[38px] w-[40px] cursor-pointer items-center justify-center border-none bg-du-skyblue"
          >
            <CaretDown
              className="h-4 w-4"
              fillColor="white"
              onClick={() => {
                onComboClick;
              }}
            />
          </div>
        </div>
      </div>

      {selectOpen && (
        <div
          id="divCntry1"
          ref={DropDownRef}
          className="relative  h-[120px]  w-full items-center justify-center overflow-y-auto 
          overflow-x-hidden border-[#ADADAD]   pl-2.5 text-[15px] 
          font-medium outline outline-offset-1 outline-gray-300"
          role="listbox"
          aria-labelledby="combo1-label"
          tabIndex={-1}
        >
          {option.map((option, index) => (
            <div
              key={index}
              id={`${idBase}-${index}`}
              role="option"
              aria-selected={selectedOption?.value === option.value}
              onKeyDown={(e) => handleOptionSelectKey(option, e)}
              onClick={() => {
                handleOptionSelect(option, index);
                onOptionClick(index);
              }}
              className={`dropdown-option ${
                index === activeIndex ? "highlighted" : ""
              } getting-over-by-key hover:bg-gray-300 ${
                index === 0 ? "combo-option option-current" : "combo-option"
              } w-full p-3 text-sm font-medium`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const MilitaryLocationDate: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const [selectedMoveValue, setSelectedMoveValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRef1 = useRef<HTMLInputElement>(null);


  useEffect(() => {
    document.body.style.overflowX = "hidden";

    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  const willClickEvent = (event: React.MouseEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSelectedValue(value);
    setSelectedMoveValue("");
    if (value === "1") {
      setShowDiv(false);
    } else {
      setShowDiv(true);
    }
    const customEvent = new CustomEvent("willClickEventValueUpdate", {
      detail: event.currentTarget.value,
    });
    window.dispatchEvent(customEvent);
  };

  const keyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    } else if (event.key === "Enter") {
      setSelectedValue(event.currentTarget.value);
      setSelectedMoveValue("");
      const customEvent = new CustomEvent("willClickEventValueUpdate", {
        detail: event.currentTarget.value,
      });
      window.dispatchEvent(customEvent);
    }
  };

  // const handleErrorsInfo = () => {
  //   console.log("From angular to react");
  // };

  const willMoveEvent = (event: React.MouseEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSelectedMoveValue(value);
    const customEvent = new CustomEvent("willMoveEventValueUpdate", {
      detail: event.currentTarget.value,
    });
    window.dispatchEvent(customEvent);
  };

  const keyDownHasMoveEvent = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    } else if (event.key === "Enter") {
      setSelectedMoveValue(event.currentTarget.value);
      const customEvent = new CustomEvent("willMoveEventValueUpdate", {
        detail: event.currentTarget.value,
      });
      window.dispatchEvent(customEvent);
    }
  };

  useEffect(() => {

    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (inputRef1.current) {
      inputRef1.current.focus();
    }
    const focusable: any = document.getElementById('divCntry')
    console.log(focusable)
    focusable.contentEditable = true;
    focusable?.focus()
    focusable.contentEditable = false;
  }, []);

  return (
    <div>
      <h2 className="font-secondary text-2xl font-light">
        Update Your Personal Information
      </h2>
      <br />
      <br />
      <div>
        <p className="font-secondary text-xs">*Required fields</p>
        <label className="font-secondary text-sm font-bold" id="lblCr">
          Country of Residence for Upcoming Term*
        </label>
        <br />
        <DropDownList></DropDownList>
      </div>
      <div className="items-start">
        <label
          id="errorMessage"
          style={{ top: "0" }}
          className="relative font-secondary text-xs text-[#a30606]"
        ></label>
      </div>
      <br />
      <label
        id="willMove"
        className="relative top-[-10px] font-secondary text-sm font-bold"
      >
        Will you be moving in the next 6 months?*
      </label>
      <br />
      <div role="radiogroup" aria-labelledby="willMove errorMessage1">
        <div
          id="nxt6Months1"
          className="inline-flex"
          style={{ fontWeight: "bold" }}
        >
          <input
            id="radioMoveYes"
            ref={inputRef}
            type="radio"
            name="radioGroup"
            value="1"
            className="border-3 relative top-[-2px] mr-2 h-5 w-5"
            // tabIndex={0}
            aria-labelledby="n6Months"
            onClick={willClickEvent}
            onKeyDown={keyDownEvent}
            checked={selectedValue === "1"}
          />
          <span className="relative  top-[-2px] mr-4" id="n6Months">
            Yes
          </span>
        </div>
        <div
          id="nxt6Months"
          className="inline-flex "
          style={{ fontWeight: "bold" }}
        >
          <input
            id="radioMoveNo"
            type="radio"
            name="radioGroup"
            value="0"
            className="border-3 relative top-[-2px] mr-2 h-5 w-5"
            // tabIndex={0}
            aria-labelledby="n6MonthsNo"
            onClick={willClickEvent}
            onKeyDown={keyDownEvent}
            checked={selectedValue === "0"}
          />
          <span id="n6MonthsNo" className="relative  top-[-2px] mr-4">
            No
          </span>
        </div>
      </div>
      <div className="items-start">
        <label
          id="errorMessage1"
          className="left-0 font-secondary text-xs text-[#a30606]"
        ></label>
      </div>

      <br />
      {showDiv && (
        <div>
          <br />
          <label
            id="lblHaveMove"
            className="relative top-[-24px] font-secondary text-sm font-bold"
          >
            Have you moved and need to update your information?*
          </label>
          <div role="radiogroup" aria-labelledby="lblHaveMove errorMessage2">
            <div
              id="willHaveMove"
              className=" inline-flex"
              style={{ fontWeight: "bold" }}
            >
              <input
                ref={inputRef1}
                id="radioHasYes"
                type="radio"
                name="radioMove"
                value="1"
                className="border-3 relative top-[-22px] mr-2 h-5 w-5 rounded-full  border-black"
                checked={selectedMoveValue === "1"}
                tabIndex={0}
                aria-labelledby="demo1"
                onClick={willMoveEvent}
                onKeyDown={keyDownHasMoveEvent}
              />
              <span className="relative top-[-22px]  mr-4" id="demo1">
                Yes
              </span>
            </div>
            <div className="inline-flex " style={{ fontWeight: "bold" }}>
              <input
                id="radioHasNo"
                type="radio"
                name="radioMove"
                value="0"
                checked={selectedMoveValue === "0"}
                className="border-3 relative top-[-22px] mr-2 h-5 w-5 rounded-full  border-black"
                tabIndex={0}
                aria-labelledby="demo2"
                onClick={willMoveEvent}
                onKeyDown={keyDownHasMoveEvent}
              />
              <span id="demo2" className="relative  top-[-22px]">
                No
              </span>
            </div>
            <div className="items-start">
              <label
                id="errorMessage2"
                className="left-0 font-secondary text-xs text-[#a30606] "
              ></label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
angularize(MilitaryLocationDate, "militaryReact", angular.module("duApp"), {});
export default MilitaryLocationDate;
