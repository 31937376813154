import * as Dialog from "@radix-ui/react-dialog";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import React, { useState } from "react";

interface ClassRegistrationEditServiceImpactProps {
  trigger: React.ReactNode;
}

const ClassRegistrationEditServiceImpact: React.FC<
  ClassRegistrationEditServiceImpactProps
> = ({ trigger }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <ModalWrapper show={isOpen} className="h-[280px] w-[55vw]">
        <div className="mb-6 flex justify-end">
          <Dialog.Close className="border-none text-3xl" aria-label="Close">
            &times;
          </Dialog.Close>
        </div>
        <Paragraph>
          There is a hold on your account that prevents you from changing
          grading options. Please contact an advisor at 855-655-8682 for
          assistance.
        </Paragraph>
        <div className="mt-[30px] flex flex-row justify-center gap-4 border-0 border-t border-solid border-du-gray pt-[30px]">
          <button className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold">
            Ok
          </button>
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default ClassRegistrationEditServiceImpact;
