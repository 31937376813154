import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";

const WeekDays = () => {
  const [checked, setChecked] = useState<string[]>([]);

  const weekList = ["Monday", "Tuesday", "Wednesday", "Thursday"];
  const weekEndList = ["Friday", "Saturday", "Sunday"];

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedList = event.target.checked
      ? [...checked, event.target.value]
      : checked.filter((day) => day !== event.target.value);

    setChecked(updatedList);

    const weekInfo = new CustomEvent("UpdatedWeekList", {
      detail: updatedList,
    });
    window.dispatchEvent(weekInfo);
  };

  useEffect(() => {
    const storedWeekInfo = localStorage.getItem("WeekInfo");
    if (storedWeekInfo) {
      setChecked(storedWeekInfo.split(","));
    }
  }, []);

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        {weekList.map((day, index) => (
          <label key={index} className="flex items-center space-x-2">
            <input
              id={day}
              value={day}
              type="checkbox"
              checked={checked.includes(day)}
              className="h-4 w-4"
              onChange={handleCheck}
            />
            <span>{day}</span>
          </label>
        ))}
        {weekEndList.map((day, index) => (
          <label key={index} className="flex items-center space-x-2">
            <input
              id={day}
              value={day}
              type="checkbox"
              checked={checked.includes(day)}
              className="h-4 w-4"
              onChange={handleCheck}
            />
            <span>{day}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

angularize(WeekDays, "weekDaysReact", angular.module("duApp"), {});
export default WeekDays;
